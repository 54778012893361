import Callbacks from '../../src/utils/Callbacks';
import VideoPlayerStore from './VideoPlayerStore';
import VideoPlayer from './VideoPlayer';
import LivePlusNotifications from '../../src/channels/subscriptions/LivePlusNotifications';
import VideoPlayerLiveStreamHelper from './VideoPlayerLiveStreamHelper';
import Logger from '../../src/shared/Logger';
import fulfillmentVideoHelper from "../../utils/FulfillmentVideoHelper";

const idealChunkDuration = 20 * 1000; // 20 sec
// 10 sec, this time shift affects where you go in the video if the indicator is
const incidentTimeShift = 10000;
const intervalLength = 4; // 4 min
const fakeChunkVideoDuration = 5016;

/**
 * Video player controller
 */
class VideoPlayerController {
  /**
   * Construct an instance of a video controller
   * @param {Date} examStarted - an exam's start date
   * @param {Date} examCompleted - an exam's complete data
   * @param {String} fulfillmentId - an exam's fulfillment identifier
   * @param {String} userId - a user identifier
   * @param {String} userUuid - a user identifier
   * @param {String} streamHost - a stream host
   * @param {String} region - AWS region for recording/chat
   */
  constructor(
    examStarted,
    examCompleted,
    fulfillmentId,
    userId,
    userUuid,
    streamHost,
    spinnerPath,
    isWatcher,
    multiPart,
    accommodations,
    durationModifierAccommodation,
    integrityBreach,
    eventAlertConfigs,
    prechecksCompletedAt,
    iceServers,
    duration,
    showStudentStatus,
    sessionUuid,
    raiseHand,
    managerRequest,
    allowedResources,
    otherResources,
    eventsUrl,
    incidentSubtypes,
    showScreenRecording,
    videoLayoutSettings,
    videoService,
    region,
    externalCameraEnabled,
    videoRecordingDisabled
  ) {
    this.handleLiveEvents = this.handleEvents.bind(this);
    this.videoPaceBarrier = 0;
    this.camVideoTime = 0;
    this.screenVideoTime = 0;
    this.videoDeviation = 0;
    this.liveTimestampInterval = null;
    this.socketErrorAppeared = false;
    this.examStarted = examStarted;
    this.examCompleted = examCompleted;
    this.fulfillmentId = fulfillmentId;
    this.userId = userId;
    this.userUuid = userUuid;
    this.streamHost = streamHost;
    this.region = region;
    this.externalCameraEnabled = externalCameraEnabled;
    this.spinnerPath = spinnerPath;
    this.isWatcher = isWatcher;
    this.isMultiPart = multiPart;
    this.accommodations = accommodations;
    this.durationModifierAccommodation = durationModifierAccommodation || null;
    this.integrityBreach = integrityBreach;
    this.eventAlertConfigs = eventAlertConfigs;
    this.examDuration = duration;
    this.showStudentStatus = showStudentStatus;
    this.prechecksCompletedAt = prechecksCompletedAt;
    this.iceServers = iceServers;
    this.sessionUuid = sessionUuid;
    this.managerRequest = managerRequest;
    this.raiseHand = raiseHand;
    this.videoPlayerStore = new VideoPlayerStore();
    this.allowedResources = allowedResources;
    this.otherResources = otherResources;
    this.eventsUrl = eventsUrl;
    this.videoLayoutSettings = videoLayoutSettings;
    this.videoService = videoService;
    this.videoRecordingDisabled = videoRecordingDisabled;
    //We tie the LivePlusNotifications to the VideoPlayerController so they have access to the onClicks for the toast.
    new LivePlusNotifications(fulfillmentId).init(
      this.handleLiveEvents
    );
    this.incidentSubtypes = incidentSubtypes;
    this.showScreenRecording = showScreenRecording;
    this.logger = new Logger();
    this.logger.setContext({
      fulfillmentId: fulfillmentId,
      userId: userId
    });

    let helperWatcher = this.isWatcher ? true : false;
    this.fulfillmentVideoHelper = new fulfillmentVideoHelper(this.fulfillmentId, this.userId, helperWatcher, this);
    this.fulfillmentVideoHelper.initializeWebSocket();
  }

  /**
   * Initialize a video player
   * @param {DOMElement} componentContainer - a container the video player has to be rendered in
   */
  init(componentContainer) {
    ReactDOM.render(
      <VideoPlayer
        fulfillmentId={this.fulfillmentId}
        userId={this.userId}
        streamHost={this.streamHost}
        examStarted={this.examStarted}
        examCompleted={this.examCompleted}
        spinnerPath={this.spinnerPath}
        videoPlayerController={this}
        videoPlayerStore={this.videoPlayerStore}
        renderWatcherOverlays={this.isWatcher}
        isMultiPart={this.isMultiPart}
        accommodations={this.accommodations}
        durationModifierAccommodation={this.durationModifierAccommodation}
        integrityBreach={this.integrityBreach}
        eventAlertConfigs={this.eventAlertConfigs}
        prechecksCompletedAt={this.prechecksCompletedAt}
        examDuration={this.examDuration}
        managerRequest={this.managerRequest}
        raiseHand={this.raiseHand}
        allowedResources={this.allowedResources}
        otherResources={this.otherResources}
        eventsUrl={this.eventsUrl}
        incidentSubtypes={this.incidentSubtypes}
        showScreenRecording={this.showScreenRecording}
        externalCameraEnabled={this.externalCameraEnabled}
        videoRecordingDisabled={this.videoRecordingDisabled}
      />,
      componentContainer
    );
  }

  /**
   * Initialize a state of the component
   */
  onBeforeRender() {
    const error = !this.examStarted
      ? "Video not available. Exam hasn't started yet."
      : null;
    const isLive = this.isLive();

    let playVideoFlag = false;

    if (this.isWatcher) {
      playVideoFlag = true;
    }

    this.videoPlayerStore.setData({
      live: isLive,
      play: playVideoFlag,
      speedValue: 1,
      durationValue: isLive ? 100 : 0,
      durationTime: 0,
      chunksDuration: 0,
      camVideo: {
        chunks: null,
        currentChunk: 0,
        currentPlayer: 1,
        error
      },
      screenVideo: {
        chunks: null,
        currentChunk: 0,
        currentPlayer: 1,
        error
      },
      videoDiscrepancy: 0,
      liveDurationTime: 0,
      lastCamVideoCurrentTime: 0,
      lastCamVideoChunk: 0,
      showStudentStatus: this.showStudentStatus
    });

    if (this.examCompleted) {
      this.fetchVideo(null, true, false, true)
        .always(() => {
          this.fetchIncidents();
        });
    }

    this.callbacks = new Callbacks();
  }

  /**
   * Adds event listeners and creates ws connection if stream is live
   * @param {Object} refs - dom nodes object
   */
  onAfterRender(refs) {
    this.refs = refs;
    const { camVideo, screenVideo } = refs;

    const videoLayoutSettings = {
      videoLayout: this.videoLayoutSettings.id,
      combinedStreamMaxBandwidth: this.videoLayoutSettings.combined_video_bitrate_kbps,
      combinedStreamFrameRate:  this.videoLayoutSettings.frames_per_second,
      secondCameraMaxBandwidth: this.videoLayoutSettings.individual_video_bitrate_kbps,
    };

    if (this.videoPlayerStore.videoData.live) {
      this.playerLiveStreamHelper = new VideoPlayerLiveStreamHelper(
        camVideo,
        screenVideo,
        this.userId,
        this.userUuid,
        this.fulfillmentId,
        this.streamHost,
        this.socketErrorHandler.bind(this),
        this.iceServers,
        this.stopTimer.bind(this),
        this.videoPlayerStore,
        this.sessionUuid,
        videoLayoutSettings,
        this.refs,
        this.videoService,
        this.region,
      );
      this.playerLiveStreamHelper.setup();
    }

    if (this.examStarted) {
      this.callbacks.on(this.handleEvents.bind(this));
      this.callbacks.fire({ type: 'getAllIncidents' });
    }
  }

  /**
   * Removes event listeners and disconnect ws connection if stream is live
   */
  onAfterDestroy() {
    this.playerLiveStreamHelper && this.playerLiveStreamHelper.shutDown();
    this.callbacks.off(this.handleEvents);
  }

  /**
   * Check whether a player is in a live mode
   * @returns {Boolean} true if a player is in a live mode or false otherwise
   */
  isLive() {
    return this.examStarted && !this.examCompleted && !this.socketErrorAppeared;
  }

  /**
   * Calculates summary duration of array of video chunks
   * @param {Array} chunks - an array of video chunks
   * @returns {Number} video duration
   */
  calculateChunksDuration(chunks) {
    return chunks
      .map(chunk => chunk.duration)
      .reduce((accumulator, currentValue) => accumulator + currentValue);
  }

  /**
   * Calculates start date of first fake chunk
   * @param {Object} videoToExtend - video to extend data
   * @returns {Number} start date of first fake chunk
   */
  calculateFirstFakeChunkStartDate(videoToExtend) {
    const lastChunk = videoToExtend.chunks[videoToExtend.chunks.length - 1];
    return (
      new Date(lastChunk.startDate).getTime() +
      lastChunk.duration -
      fakeChunkVideoDuration
    );
  }

  /**
   * Increase array of chunks so that the duration of the videos (screen and camera) matches
   * @param {Object} streams - camera video and screen video info
   */
  addFakeChunksToStream(streams) {
    const {
      baseVideo,
      videoToExtend,
      baseVideoDuration,
      videoToExtendDuration
    } = streams;

    const delta = baseVideoDuration - videoToExtendDuration;
    const fakeChunksNumber = Math.ceil(delta / fakeChunkVideoDuration);

    let firstFakeChunkStartDate = videoToExtend.chunks.length
      ? this.calculateFirstFakeChunkStartDate(videoToExtend)
      : new Date(baseVideo.chunks[0].startDate).getTime();

    videoToExtend.chunks.push(
      ...Array.from({ length: fakeChunksNumber }, () => {
        firstFakeChunkStartDate += fakeChunkVideoDuration;
        return {
          duration: fakeChunkVideoDuration,
          startDate: new Date(
            firstFakeChunkStartDate + fakeChunkVideoDuration
          ).toISOString(),
          url:
            'https://s3-us-west-1.amazonaws.com/archimedes-emptyvideochunk/Black+Screen+(Short).mp4'
        };
      })
    );
  }

  /**
   * Configure fake chunks method and runs it
   * @param {Boolean} condition - fake chunks method configured depends on condition
   * @param {Object} videosData - camera video and screen video info
   * @param {Object} videosData.camVideo - camera video data
   * @param {Object} videosData.screenVideo - screen video data
   * @param {Number} videosData.calculatedChunksDurationCamVideo - camera video duration
   * @param {Number} videosData.calculatedChunksDurationScreenVideo - screen video duration
   */
  configureAndRunAddFakeChunksMethod(
    condition,
    {
      camVideo,
      screenVideo,
      calculatedChunksDurationCamVideo,
      calculatedChunksDurationScreenVideo
    }
  ) {
    this.addFakeChunksToStream(
      condition
        ? {
          baseVideo: screenVideo,
          videoToExtend: camVideo,
          baseVideoDuration: calculatedChunksDurationScreenVideo,
          videoToExtendDuration: calculatedChunksDurationCamVideo
        }
        : {
          baseVideo: camVideo,
          videoToExtend: screenVideo,
          baseVideoDuration: calculatedChunksDurationCamVideo,
          videoToExtendDuration: calculatedChunksDurationScreenVideo
        }
    );
  }

  /**
   * Calculates video duration and extends video if needed
   * @param {Object} camVideo - camera video data
   * @param {Object} screenVideo - screenVideo video data
   */
  extendVideoIfNeeded(camVideo, screenVideo) {
    const calculatedChunksDurationCamVideo = camVideo.chunks.length
      ? this.calculateChunksDuration(camVideo.chunks)
      : 0;

    const calculatedChunksDurationScreenVideo = screenVideo.chunks.length
      ? this.calculateChunksDuration(screenVideo.chunks)
      : 0;

    const videosData = {
      camVideo,
      screenVideo,
      calculatedChunksDurationCamVideo,
      calculatedChunksDurationScreenVideo
    };

    if (
      (!camVideo.chunks.length && screenVideo.chunks.length) ||
      (camVideo.chunks.length && !screenVideo.chunks.length)
    ) {
      this.configureAndRunAddFakeChunksMethod(
        screenVideo.chunks.length,
        videosData
      );
    } else if (
      Math.abs(
        calculatedChunksDurationCamVideo - calculatedChunksDurationScreenVideo
      ) >= fakeChunkVideoDuration
    ) {
      this.configureAndRunAddFakeChunksMethod(
        calculatedChunksDurationCamVideo < calculatedChunksDurationScreenVideo,
        videosData
      );
    }
  }

  /**
   * Fetch incidents
   * @returns {Promise}
   */
  fetchIncidents() {
    const request = {
      url: '/api/timeline_grid',
      data: {
        uuid: this.fulfillmentId
      }
    };
    return $.ajax(request)
      .then(({ incidents }) => {
        this.videoPlayerStore.setData({ incidents });
      });
  }

  /**
   * Fetch video
   * @param {Date} startDate - a start point of time a video should be fetched from
   * @param {Boolean} foreground - determines if fetching data happens in the background or in the foreground
   * @param {Boolean} joinChunks - determines if chunks have to be joined or not
   * @param {Boolean} isFirstRequest - determines if request made on page load
   */
  fetchVideo(startDate = null, foreground = true, joinChunks = false, isFirstRequest = false) {
    if (this.socketErrorAppeared && this.videoPlayerStore.videoData.live) {
      this.videoPlayerStore.setData({ live: false });
    }
    const request = {
      url: '/api/videos',
      dataType: 'json',
      data: {
        uuid: this.fulfillmentId,
        interval_length: intervalLength * 60 * 1000
      }
    };
    startDate && (request.data.time_mark = startDate);
    foreground && this.videoPlayerStore.setData({fetching: true});
    return $.ajax(request)
      .then(
        (data) => {
          var { camVideo, screenVideo, videoEndsAt: endDate, videoDuration, examSoftVideoEmpty } = data;

          if(!this.showScreenRecording) {
            screenVideo = null;
          }

          if (examSoftVideoEmpty && isFirstRequest) {
            const errorState = 'Video exam has not been processed';
            this.setErrorState(errorState, foreground);
            return;
          }

          if (videoDuration) {
            this.videoDuration = videoDuration;
          }
          if (camVideo && screenVideo) {
            this.extendVideoIfNeeded(camVideo, screenVideo);
          }

          const calculateVideoPace = state => {
            const { live, videoPace } = this.videoPlayerStore.videoData;
            if (!this.isLive()) {
              return !videoPace
                ? this.calculateVideoPace(
                  state,
                  refinedExamCompleted || endDate
                )
                : videoPace;
            }
            return this.calculateVideoPace(state);
          };
          const refinedExamCompleted = this.processVideoChunks(
            camVideo,
            screenVideo,
            startDate,
            this.examCompleted ? endDate : null,
            joinChunks
          );

          if (foreground) {
            this.processForegroundRequest(
              camVideo,
              screenVideo,
              startDate,
              joinChunks,
              calculateVideoPace,
              data
            );
          } else {
            this.processBackgroundRequest(
              camVideo,
              screenVideo,
              calculateVideoPace
            );
          }
          return data;
        },
        error => {
          const errorState = `Failed to retrieve video: ${error.statusText}`;
          this.logger.error(errorState, error);
          this.setErrorState(errorState, foreground);
        }
      )
      .always(
        () => foreground && this.videoPlayerStore.setData({ fetching: false })
      );
  }

  setErrorState(errorState, foreground) {
    this.videoPlayerStore.setData({
      camVideo: {
        ...this.videoPlayerStore.videoData.camVideo,
        error: errorState
      },
      screenVideo: {
        ...this.videoPlayerStore.videoData.screenVideo,
        error: errorState
      }
    });
    this.videoPlayerStore.videoData.play &&
    foreground &&
    this.togglePlayPause();
  }
  /**
   * Process video chunks
   * @param {Object} camVideo - camera video data
   * @param {Object} screenVideo - screen video data
   */
  processBackgroundRequest(camVideo, screenVideo, calculateVideoPace) {
    const {
      camVideo: camVideoState,
      screenVideo: screenVideoState
    } = this.videoPlayerStore.videoData;

    if (
      camVideo &&
      !(camVideo.chunks.length === 1 &&
        camVideoState.chunks[camVideoState.chunks.length - 1].duration === camVideo.chunks[0].duration)
    ) {
      camVideoState.chunks = camVideoState.chunks.concat(
        camVideo.chunks || []
      );
    }

    if (
      screenVideo &&
      !(screenVideo.chunks.length === 1 &&
        screenVideoState.chunks[screenVideoState.chunks.length - 1].duration === screenVideo.chunks[0].duration)
    ) {
      screenVideoState.chunks = screenVideoState.chunks.concat(
        screenVideo.chunks || []
      );
    }

    this.videoPlayerStore.setData({
      camVideo: camVideoState,
      screenVideo: screenVideoState,
      videoPace: calculateVideoPace(camVideoState),
      error: null
    });
  }

  /**
   * Process video chunks
   * @param {Object} camVideo - camera video data
   * @param {Object} screenVideo - screen video data
   * @param {Date} startDate - a start point of time a video should be fetched from
   */
  processForegroundRequest(
    camVideo,
    screenVideo,
    startDate,
    joinChunks,
    calculateVideoPace,
    data
  ) {
    if (!camVideo && !screenVideo) {
      this.isLive() && this.toggleLive();
      this.videoPlayerStore.setData({
        camVideo: {
          chunks: null,
          currentChunk: 0,
          currentPlayer: 1,
          error: null
        },
        screenVideo: {
          chunks: null,
          currentChunk: 0,
          currentPlayer: 1,
          error: null
        },
        chunksDuration: 0
      });
      return data;
    }
    let positionInChunk = 0;
    if (camVideo.chunks) {
      this.syncVideo(camVideo, screenVideo);
      if (this.videoPlayerStore.videoData.play) {
        this.playPauseVideo(false);
        setTimeout(this.playPauseVideo.bind(this, true), 0);
      }
      positionInChunk = startDate
        ? this.findPositionInChunk(camVideo.chunks, startDate)
        : 0;
      if (!joinChunks) {
        this.calculateSyncTime(camVideo, screenVideo, startDate);
      }
    }
    const {
      camVideo: camVideoState,
      screenVideo: screenVideoState
    } = this.videoPlayerStore.videoData;

    const newCamVideoState = {
      ...camVideoState,
      chunks: joinChunks
        ? [].concat(
          mobx.toJS(camVideoState.chunks) || [],
          camVideo ? camVideo.chunks : []
        )
        : camVideo
          ? camVideo.chunks
          : null,
      positionInChunk,
      error: null
    };
    const newScreenVideoState = {
      ...screenVideoState,
      chunks: joinChunks
        ? [].concat(
          mobx.toJS(screenVideoState.chunks) || [],
          screenVideo ? screenVideo.chunks : []
        )
        : screenVideo
          ? screenVideo.chunks
          : null,
      positionInChunk,
      error: null
    };
    this.videoPlayerStore.setData({
      camVideo: newCamVideoState,
      screenVideo: newScreenVideoState,
      videoPace: calculateVideoPace(newCamVideoState)
    });
  }

  /**
   * Socket error handler
   */
  socketErrorHandler() {
    this.socketErrorAppeared = true;
    const errorState =
      'Student reconnected. Please refresh the page to continue watching.';
    this.videoPlayerStore.setData({
      socketErrorAppeared: this.socketErrorAppeared,
      camVideo: {
        ...this.videoPlayerStore.videoData.camVideo,
        error: errorState
      },
      screenVideo: {
        ...this.videoPlayerStore.videoData.screenVideo,
        error: errorState
      }
    });
  }

  /**
   * Stops watcher timer
   */
  stopTimer() {
    this.videoPlayerStore.setData({ isTimerStopped: true })
  }

  handleLiveEvents(event) {
    this.callbacks.fire(event);
  }

  /**
   * Handle various events
   * @param {Object} event - an incoming event
   */
  handleEvents(event) {
    const eventHandlers = {
      showEvent: data => {
        const { incident: { createdAtISO, starts_at: startsAt, type, uiMessageId } } = data;
        const { incidents } = this.videoPlayerStore.videoData;
        if (!this.isLive()) {
          if (incidents && incidents.length) {
            const incident = incidents.find(incident => incident.id === uiMessageId);
            if (incident) {
              this.onPositionChange(incident.position / this.videoDuration * 100);
            } else {
              this.videoPlayerStore.setData({ 'error': "Can't rewind to an event, its date incorrect." });
            }
          }
        } else {
          const createdDate =
            type == 'Event::SuspiciousBehavior' ? startsAt : createdAtISO;
          const incidentPosition = this.findIncidentPosition(createdDate);
          if (incidentPosition) {
            const { position, date } = incidentPosition;
            this.onPositionChange(position, date);
          } else {
            this.videoPlayerStore.setData({ 'error': "Can't rewind to an event, its date incorrect." });
          }
        }
      },
      newIncident: data => {
        this.videoPlayerStore.setData({
          incidents: this.videoPlayerStore.videoData.incidents || [].push(data)
        });
      },
      allIncidents: data => this.videoPlayerStore.setData({ incidents: data })
    };

    const eventHandler = eventHandlers[event.type];
    eventHandler && eventHandler(event.data);
  }

  /**
   * Process video chunks
   * @param {Object} camVideo - camera video data
   * @param {Object} screenVideo - screen video data
   * @param {Date} startDate - a start point of time a video should be fetched from
   * @param {Date} endDate - an end date point of time a video should be fetched from
   * @param {Boolean} joinChunks - checks if chunks should be joined or not
   */
  processVideoChunks(camVideo, screenVideo, startDate, endDate, joinChunks) {
    let {
      videoDiscrepancy,
      refinedExamCompleted
    } = this.videoPlayerStore.videoData;

    if (camVideo) {
      const { chunks } = camVideo;
      let firstChunkStartDate = null;
      if (!joinChunks && chunks.length) {
        if (startDate) {
          firstChunkStartDate = new Date(
            new Date(chunks[0].startDate).getTime() + videoDiscrepancy
          );
        } else {
          firstChunkStartDate = new Date(this.examStarted);
          videoDiscrepancy =
            firstChunkStartDate.getTime() -
            new Date(chunks[0].startDate).getTime();
          this.videoPlayerStore.setData({ videoDiscrepancy });
        }
      }
      this.processChunks(chunks, 'camVideo', joinChunks, firstChunkStartDate);

      if (!startDate && !refinedExamCompleted && endDate) {
        refinedExamCompleted = new Date(
          new Date(endDate).getTime() + videoDiscrepancy
        ).toISOString();
        this.videoPlayerStore.setData({ refinedExamCompleted });
      }
    }

    if (screenVideo) {
      const { chunks } = screenVideo;
      this.processChunks(
        chunks,
        'screenVideo',
        joinChunks,
        chunks.length
          ? new Date(new Date(chunks[0].startDate).getTime() + videoDiscrepancy)
          : null
      );
    }

    return refinedExamCompleted;
  }

  /**
   * Process chunks
   * @param {Array} chunks - chunks to be processed
   * @param {String} chunksType - a chunks' type
   * @param {Boolean} joinChunks - checks if chunks should be joined or not
   * @param {Date} firstStartDate - a date of the first start date
   */
  processChunks(chunks, chunksType, joinChunks, firstChunkStartDate) {
    let chunkStartTime;
    const {
      [chunksType]: { chunks: existingChunks }
    } = this.videoPlayerStore.videoData;
    if (joinChunks && existingChunks) {
      const {
        startDate: lastChunkStartDate,
        duration: lastChunkDuration
      } = existingChunks[existingChunks.length - 1];
      chunkStartTime = lastChunkStartDate.getTime() + lastChunkDuration;
    } else {
      chunkStartTime = firstChunkStartDate && firstChunkStartDate.getTime();
    }
    chunks.forEach(chunk => {
      chunk.startDate = new Date(chunkStartTime);
      chunkStartTime += chunk.duration;
    });
  }

  /**
   * Calculate a video duration
   * @return {Number} video duration in ms
   */
  calculateVideoDuration(chunks) {
    if (this.examCompleted) {
      return new Date(this.examCompleted) - new Date(this.examStarted);
    }
    return new Date() - new Date(this.examStarted);
  }

  /**
   * Calculate a video pace
   * @param {Array} chunks - a collection of chunks
   * @param {String} endDate - an end date of video
   * @returns {Number} a video pace (amount of seconds for 1 step
   */
  calculateVideoPace({ chunks }, endDate = null) {
    if (chunks && chunks.length) {
      const videoDuration = endDate
        ? new Date(endDate).getTime() - chunks[0].startDate.getTime()
        : this.calculateVideoDuration();
      return chunks ? videoDuration / 1000 / 100 : 0;
    }
    return 0;
  }

  /**
   * Calculate a sync time between cam and screen video streams
   * @param {Object} camVideo - a cam video data
   * @param {Object} screenVideo - a screen video data
   * @param {Date} startDate - a start date of a video
   */
  calculateSyncTime(camVideo, screenVideo, startDate = null) {
    if (!camVideo || !camVideo.chunks || !screenVideo || !screenVideo.chunks) {
      return;
    }

    const camVideoStartDate = startDate || camVideo.chunks[0].startDate;
    const screenVideoStartDate = screenVideo.chunks[0].startDate;
    if (camVideoStartDate < screenVideoStartDate) {
      this.videoPlayerStore.setData({
        syncTime: this.findChunkAndPosition(
          camVideo.chunks,
          screenVideoStartDate
        )
      });
    }
  }

  /**
   * Synchronize camera video and screen video
   * @param {Object} camVideo - a cam video data
   * @param {Object} screenVideo - a screen video data
   */
  syncVideo(camVideo = null, screenVideo = null) {
    const {
      screenVideo: screenVideoState,
      camVideo: camVideoState
    } = this.videoPlayerStore.videoData;
    this.camVideoReadyState =
      camVideo || camVideoState.chunks ? $.Deferred() : null;
    this.screenVideoReadyState =
      screenVideo || screenVideoState.chunks ? $.Deferred() : null;

    clearTimeout(this.syncVideoTimer);
    this.syncVideoTimer = setTimeout(() => {
      const checkSyncPromise = (promise, playerName) =>
        promise && promise.state() == 'pending' && this.refs[playerName].load();

      checkSyncPromise(
        this.camVideoReadyState,
        `camVideo_${camVideoState.currentPlayer}`
      );
      checkSyncPromise(
        this.screenVideoReadyState,
        `screenVideo_${screenVideoState.currentPlayer}`
      );
    }, 5000);
  }

  /**
   * Handles video end.
   * @param {String} name - a stream name
   * @param {Object} state - a current video state
   */
  onVideoEnd(name, state) {
    const { currentPlayer, currentChunk, chunks } = state;
    if (!chunks) return;

    const newState = {};
    const nextChunk = currentChunk + 1;
    const isMainStream = name == 'camVideo';
    const oppositeVideoName = isMainStream ? 'screenVideo' : 'camVideo';
    const oppositeVideoState = this.videoPlayerStore.videoData[
      oppositeVideoName
      ];

    if (!this.videoPlayerStore.videoData.syncTime) {
      const chunkDuration = chunks[currentChunk].duration / 1000;
      if (isMainStream) {
        this.camVideoTime += chunkDuration;
      } else {
        this.screenVideoTime += chunkDuration;
      }
    }

    if (chunks[nextChunk]) {
      const nextPlayer = currentPlayer == 1 ? 2 : 1;
      newState[name] = {
        ...state,
        currentChunk: nextChunk,
        currentPlayer: nextPlayer,
        error: null
      };
      const player = this.refs[`${name}_${nextPlayer}`];
      player.playbackRate = this.videoPlayerStore.videoData.speedValue;
      player.play();
      this.videoPlayerStore.setData(newState);

      oppositeVideoState[`error_${currentPlayer}`] &&
      this.onVideoEnd(oppositeVideoName, oppositeVideoState);

      // Fetch video in advance before one minute left
      isMainStream &&
      chunks.length - nextChunk == 3 &&
      this.fetchVideo(
        this.calculateNextChunksBlockDate(chunks.slice(chunks.length - 3)),
        false,
        true
      ).then(this.adjustPlayPosition.bind(this, this.videoPlayerStore.videoData.videoPace));
    } else {
      newState[name] = {
        ...state,
        currentChunk: nextChunk,
        currentPlayer: 1
      };

      if (isMainStream) {
        if (this.isLive()) {
          this.fetchVideo(
            this.calculateNextChunksBlockDate(chunks, true),
            true,
            true
          ).then(
            this.adjustPlayPosition.bind(
              this,
              this.videoPlayerStore.videoData.videoPace
            )
          );
        } else {
          newState.durationValue = 100;
          this.togglePlayPause();
        }
      }
      this.videoPlayerStore.setData(newState);
    }
  }

  /**
   * Adjust a play position
   * @param {Number} oldVideoPace - old video pace
   * @param {Object} data - a new data
   */
  adjustPlayPosition(oldVideoPace, data) {
    const { camVideo } = data;
    if (camVideo) {
      const { durationValue, videoPace } = this.videoPlayerStore.videoData;
      const videoPaceDifferencePercents = oldVideoPace / videoPace;
      this.videoPlayerStore.setData({
        durationValue: durationValue * videoPaceDifferencePercents
      });
    }
  }

  /**
   * Calculate start date for a next chunks' block
   * @param {Array} chunks - the all known video chunks
   * @param {Boolean} forLive - calculate for live
   * @returns {Number} the calculated duration for a new chunk's block
   */
  // TODO: refactoring
  calculateNextChunksBlockDate(chunks, forLive = false) {
    if (forLive) {
      const {
        startDate: lastChunkStartDate,
        duration: lastChunkDuration
      } = chunks[chunks.length - 1];
      return (
        lastChunkStartDate.getTime() +
        lastChunkDuration -
        this.videoPlayerStore.videoData.videoDiscrepancy
      );
    } else {
      const chunksDuration =chunks.
      map(chunk => chunk.duration).
      reduce((prevChunkDuration, nextChunkDuration) => prevChunkDuration + nextChunkDuration, 0);
      const examStartedDate = new Date(this.examStarted),
        examCompletedDate = this.examCompleted
          ? null
          : new Date();
      const examDuration =
        !examCompletedDate
          ? this.videoDuration
          : (examCompletedDate.getTime() - examStartedDate.getTime() - this.videoPlayerStore.videoData.videoDiscrepancy);
      return (examDuration * this.videoPlayerStore.videoData.durationValue / 100 + chunksDuration);
    }
  }

  /**
   * Toggles live video mode.
   */
  toggleLive() {
    const {
      live,
      play,
      camVideo: { chunks },
      durationValue,
      chunksDuration
    } = this.videoPlayerStore.videoData;
    const newState = { live: !live };
    if (live) {
      !chunks && this.fetchVideo();
      newState.durationValue = chunksDuration;
    } else {
      newState.chunksDuration = durationValue != 100 ? durationValue : 0;
      newState.durationValue = 100;
      setTimeout(() => this.togglePlayPause(), 0);
    }
    this.manageLiveTimestampInterval(!live);
    play && this.togglePlayPause();
    this.videoPlayerStore.setData(newState);
  }

  /**
   * Manages interval for live timestamp calculation
   * @param {Boolean} on - is stream is playing
   */
  manageLiveTimestampInterval(on) {
    if (on) {
      const examStartTime = new Date(this.examStarted);
      const currentTime = new Date();
      !this.liveTimestampInterval &&
      this.getLiveExamDuration(
        (currentTime.getTime() - examStartTime.getTime()) / 1000
      );
    } else {
      this.liveTimestampInterval && clearInterval(this.liveTimestampInterval);
      this.liveTimestampInterval = null;
    }
  }

  /**
   * Mutes or un-mutes video volume.
   */
  changeSpeedValue(speed) {
    const { screenVideo_1, screenVideo_2, camVideo_1, camVideo_2 } = this.refs;
    [screenVideo_1, screenVideo_2, camVideo_1, camVideo_2].forEach(
      video => (video.playbackRate = speed)
    );

    if (this.videoPlayerStore.videoData.play) {
      clearInterval(this.videoPlayInterval);
      this.videoPlayInterval = setInterval(
        this.onVideoTimeUpdate.bind(this),
        1000 / speed
      );
    }

    this.videoPlayerStore.setData({ speedValue: speed });
  }

  /**
   * Play or pause a video.
   * @param {Boolean} on - true turns a video on, otherwise turns it off
   */
  playPauseVideo(on) {
    const {
      camVideo: camVideoState,
      screenVideo: screenVideoState,
      live,
      speedValue,
      syncTime
    } = this.videoPlayerStore.videoData;
    const camVideo = live
      ? this.refs.camVideo
      : this.refs[`camVideo_${camVideoState.currentPlayer}`];
    const screenVideo = live
      ? this.refs.screenVideo
      : this.refs[`screenVideo_${screenVideoState.currentPlayer}`];

    if(!this.showScreenRecording) {
      const screenVideo = {
        chunks: null,
        currentChunk: 0,
        currentPlayer: 1,
        error: null
      };
    }

    const positionVideo = () => {
      screenVideo.currentTime = screenVideoState.positionInChunk || 0;
      camVideo.currentTime = camVideoState.positionInChunk || 0;
    };
    const playVideo = () => {
      if (!live) {
        this.videoPlayInterval = setInterval(
          this.onVideoTimeUpdate.bind(this),
          1000 / speedValue
        );
        camVideo.playbackRate = speedValue;
        if(this.showScreenRecording) {
          screenVideo.playbackRate = speedValue;
        }
      } else {
        this.manageLiveTimestampInterval(true);
      }

      if (live && !this.playerLiveStreamHelper.isLiveStreamStarted()) {
        this.playerLiveStreamHelper.startStream();
      }
    };
    const pauseVideo = () => {
      if (live) {
        this.manageLiveTimestampInterval(false);
      } else {
        clearInterval(this.videoPlayInterval);
      }
      if (this.showScreenRecording) {
        screenVideo.pause();
      }
      camVideo.pause();
      if (live && this.playerLiveStreamHelper.isLiveStreamStarted()) {
        this.playerLiveStreamHelper.stopStream();
      }


    };

    if (on) {
      if (this.camVideoReadyState || this.screenVideoReadyState) {
        return $.when(this.camVideoReadyState, this.screenVideoReadyState)
          .then(
            () => {
              positionVideo();
              playVideo();
            },
            () => {
              if (
                this.camVideoReadyState.state() == 'resolved' ||
                this.screenVideoReadyState.state() == 'resolved'
              ) {
                positionVideo();
                playVideo();
              }
            }
          )
          .always(() => {
            this.camVideoReadyState = this.screenVideoReadyState = null;
            clearTimeout(this.syncVideoTimer);
          });
      }
      playVideo();
      if(live) {
        const sender = this.isWatcher ? 'archimedes_watcher_window' : 'archimedes_fulfillment_page';
        const message = this.fulfillmentVideoHelper.constructWsMessage(sender);
        this.fulfillmentVideoHelper.broadcastMessage(message);

        if (!this.isWatcher) {
          this.fulfillmentVideoHelper.showPauseModel(false);
        }
      }
    } else {
      pauseVideo();
    }
  }

  /**
   * Toggle play/pause video state.
   */
  togglePlayPause() {
    const play = !this.videoPlayerStore.videoData.play;
    const $promise = this.playPauseVideo(play);
    this.videoPlayerStore.setData({ play });
    return $promise;
  }

  /**
   * Updates duration value
   * @param {Number} value - a new video position
   */
  changePosition(value) {
    this.videoPlayerStore.setData({
      durationValue: value,
      durationTime: this.videoDuration
        ? (this.videoDuration * value / (1000 * 100))
        : null
    });
  }

  /**
   * Handle a video position change
   * @param {Number} newPosition - a new video position
   * @param {Date} rewindDate - an exam rewind date
   */
  onPositionChange(newPosition, rewindDate = null) {
    const { examStarted, examCompleted } = this;
    const {
      camVideo,
      camVideo: { chunks: camVideoChunks, currentChunk: camVideoCurrentChunk },
      screenVideo,
      screenVideo: {
        chunks: screenVideoChunks,
        currentChunk: screenVideoCurrentChunk
      },
      play,
      live,
      speedValue,
      refinedExamCompleted,
      videoDiscrepancy
    } = this.videoPlayerStore.videoData;

    if (this.isLive() && newPosition == 100 && !live) {
      this.toggleLive();
      return;
    } else if (live && play) {
      this.playPauseVideo(false);
    }

    const examStartedDate = new Date(examStarted),
      examCompletedDate = this.examCompleted
        ? null
        : new Date();
    const examDuration =
      !examCompletedDate
        ? this.videoDuration
        : (examCompletedDate.getTime() - examStartedDate.getTime() - videoDiscrepancy);

    const examRewindDate = rewindDate || new Date(examStartedDate.getTime() + examDuration * newPosition / 100);

    const camVideoChunkAndPosition = this.findChunkAndPosition(
      camVideoChunks,
      examRewindDate
    );
    let screenVideoChunkAndPosition = this.findChunkAndPosition(
      screenVideoChunks,
      examRewindDate
    );
    if (camVideoChunkAndPosition != null) {
      if (
        camVideoChunkAndPosition.chunk == camVideoCurrentChunk &&
        (!screenVideo ||
          (screenVideoChunkAndPosition &&
            screenVideoChunkAndPosition.chunk == screenVideoCurrentChunk))
      ) {
        this.refs[`camVideo_${camVideo.currentPlayer}`].currentTime =
          camVideoChunkAndPosition.positionInChunk;
        this.refs[`screenVideo_${screenVideo.currentPlayer}`].currentTime =
          screenVideoChunkAndPosition.positionInChunk;
        if (play) {
          this.videoPaceBarrier = 0;
          this.camVideoTime = this.screenVideoTime = 0;
          this.videoDeviation = 0;
          clearInterval(this.videoPlayInterval);
          this.videoPlayInterval = setInterval(
            this.onVideoTimeUpdate.bind(this),
            1000 / speedValue
          );
        }
        this.videoPlayerStore.setData({
          durationValue: newPosition,
          live: false,
          lastCamVideoCurrentTime: camVideoChunkAndPosition.positionInChunk
        });
        return;
      }

      if (play) {
        this.playPauseVideo(false);
        this.syncVideo();
        if(
          !screenVideoChunkAndPosition ||
          screenVideoChunkAndPosition.chunk == screenVideoCurrentChunk
        ) {
          this.screenVideoReadyState && this.screenVideoReadyState.resolve();
        }
        setTimeout(this.playPauseVideo.bind(this, true), 0);
      }

      this.calculateSyncTime(camVideo, screenVideo, examRewindDate);

      if (
        !screenVideoChunkAndPosition &&
        screenVideoChunks &&
        screenVideoChunks[0].startDate > examRewindDate
      ) {
        screenVideoChunkAndPosition = { chunk: 0, positionInChunk: 0 };
      }
    } else {
      this.fetchVideo(newPosition
        ? (examDuration * newPosition / 100)
        : null);
    }

    this.videoPaceBarrier = 0;
    this.camVideoTime = this.screenVideoTime = 0;
    this.videoDeviation = 0;
    this.setNewPositionState(
      camVideo,
      screenVideo,
      newPosition,
      camVideoChunkAndPosition,
      screenVideoChunkAndPosition
    );
  }

  /**
   * Handle an updated video time position
   */
  onVideoTimeUpdate() {
    const {
      videoPace,
      durationValue,
      durationTime,
      syncTime,
      camVideo: camVideoState,
      screenVideo: screenVideoState,
      speedValue
    } = this.videoPlayerStore.videoData;
    const camVideo = this.refs[`camVideo_${camVideoState.currentPlayer}`];
    const screenVideo = this.refs[
      `screenVideo_${screenVideoState.currentPlayer}`
      ];

    if (camVideo.readyState && syncTime) {
      const {
        chunk: syncChunk,
        positionInChunk: syncPositionInChunk
      } = syncTime;
      if (
        camVideoState.currentChunk >= syncChunk &&
        camVideo.currentTime +
        (camVideoState.currentChunk != syncChunk
          ? camVideoState.chunks[camVideoState.currentChunk - 1].duration
          : 0) >=
        syncPositionInChunk
      ) {
        this.videoPlayerStore.setData({ syncTime: null });
        this.videoDeviation = syncPositionInChunk;
        screenVideo.play();
      }
    }

    if (
      camVideo.readyState &&
      screenVideo.readyState &&
      camVideo.currentTime &&
      screenVideo.currentTime
    ) {
      const videoDeviation =
        this.camVideoTime +
        camVideo.currentTime -
        (this.screenVideoTime + screenVideo.currentTime) -
        this.videoDeviation;
      if (Math.abs(videoDeviation) > 0.5) {
        const screenDuration = screenVideo.duration;
        const syncTime = screenVideo.currentTime + videoDeviation;
        screenVideo.currentTime =
          syncTime >= 0 && syncTime <= screenDuration
            ? syncTime
            : syncTime < 0
              ? 0
              : screenDuration;
      }
    }

    const timeInterval = 1 / speedValue,
      camVideoCurrentTime = camVideo.currentTime;
    const timeDelta =
      this.calculatePlayedDelta(camVideoState, camVideoCurrentTime) +
      timeInterval / 10;
    if (timeDelta >= timeInterval) {
      this.videoPlayerStore.setData({
        durationTime:
          this.videoPaceBarrier == 0
            ? durationValue * videoPace
            : durationTime + timeDelta,
        lastCamVideoCurrentTime: camVideoCurrentTime,
        lastCamVideoChunk: camVideoState.currentChunk
      });

      if ((this.videoPaceBarrier += timeDelta) >= videoPace) {
        this.videoPaceBarrier -= videoPace;
        const newDuration = durationValue + (videoPace < 1 ? 1 / videoPace : 1);
        this.videoPlayerStore.setData({
          durationValue: newDuration <= 100 ? newDuration : 100
        });
      }
    }
  }

  /**
   * Calculate a played video delta
   * @param {Object} camVideoState - a current cam video state
   * @param {Number} camVideoCurrentTime - a current cam video time
   * @return {Number} a played video delta
   */
  calculatePlayedDelta(camVideoState, camVideoCurrentTime) {
    const {
      lastCamVideoCurrentTime,
      lastCamVideoChunk
    } = this.videoPlayerStore.videoData;
    const { chunks, currentChunk } = camVideoState;

    if (lastCamVideoChunk == currentChunk) {
      return Math.abs(camVideoCurrentTime - lastCamVideoCurrentTime);
    }

    let playedTime = lastCamVideoCurrentTime;
    let nextChunk = lastCamVideoChunk + 1;
    while (nextChunk < currentChunk) {
      playedTime += chunks[nextChunk++].duration;
    }
    return Math.abs(playedTime + camVideoCurrentTime - lastCamVideoCurrentTime);
  }

  /**
   * Set new position state
   * @param {Object} camVideo - current camera video state
   * @param {Object} screenVideo - current screen video state
   * @param {Number} newPosition - a new video position
   * @param {Object} camVideoChunkAndPosition - new chunk and position in a chunk for cam video
   * @param {Object} screenVideoChunkAndPosition - new chunk and position in a chunk for screen video
   */
  setNewPositionState(
    camVideo,
    screenVideo,
    newPosition,
    camVideoChunkAndPosition,
    screenVideoChunkAndPosition
  ) {
    const initialVideoState = {
      chunks: null,
      currentChunk: 0,
      currentPlayer: 1,
      error: null,
      error_1: null,
      error_2: null
    };

    let newCamVideoState,
      newScreenVideoState,
      lastCamVideoCurrentTime = 0,
      lastCamVideoChunk = 0;
    if (camVideoChunkAndPosition) {
      const { chunk, positionInChunk } = camVideoChunkAndPosition;
      newCamVideoState = {
        ...camVideo,
        currentChunk: chunk,
        positionInChunk,
        currentPlayer: 1
      };
      lastCamVideoCurrentTime = positionInChunk;
      lastCamVideoChunk = chunk;
    } else {
      newCamVideoState = { ...initialVideoState };
    }
    if (screenVideoChunkAndPosition) {
      const { chunk, positionInChunk } = screenVideoChunkAndPosition;
      newScreenVideoState = {
        ...screenVideo,
        currentChunk: chunk,
        positionInChunk,
        currentPlayer: 1
      };
    } else {
      newScreenVideoState = { ...initialVideoState };
    }

    this.videoPlayerStore.setData({
      durationValue: newPosition,
      live: false,
      camVideo: newCamVideoState,
      screenVideo: newScreenVideoState,
      lastCamVideoCurrentTime,
      lastCamVideoChunk
    });
  }

  /**
   * Find a video chunk based on the rewind date
   * @param {Array} chunks - all known video chunks
   * @param {Date} examRewindDate - a rewind date
   * @returns {Object} found next video chunk and a position in a chunk or null otherwise
   */
  findChunkAndPosition(chunks, examRewindDate) {
    if (chunks && chunks.length) {
      const { startDate: firstChunkStartDate } = chunks[0];
      const videoStart = firstChunkStartDate;
      const videoEnd = new Date(
        videoStart.getTime() + this.calculateVideoDuration()
      );
      if (examRewindDate >= videoStart && examRewindDate <= videoEnd) {
        const rewindPosition =
          (examRewindDate - videoStart.getTime()) /
          idealChunkDuration;
        const chunkIdx = Math.floor(rewindPosition),
          positionInChunk =
            ((rewindPosition - chunkIdx) * idealChunkDuration) / 1000;
        const chunk = chunks[chunkIdx];
        if (chunk) {
          const { startDate: chunkStartDate, duration: chunkDuration } = chunk;
          return examRewindDate >= chunkStartDate &&
          examRewindDate <= new Date(chunkStartDate.getTime() + chunkDuration)
            ? { chunk: chunkIdx, positionInChunk }
            : this.refineChunkAndPosition(chunks, examRewindDate, chunkIdx);
        }
        return this.refineChunkAndPosition(chunks, examRewindDate, 0);
      }
    }
    return null;
  }

  /**
   * Refine chunk and its position in it
   * @param {Array} chunks - the all known video chunks
   * @param {Date} examRewindDate - the rewind date
   * @param {Number} pivotChunkIdx - the pillar chunk index
   */
  refineChunkAndPosition(chunks, examRewindDate, pivotChunkIdx) {
    const pillarChunk = chunks[pivotChunkIdx];
    let startChunkIdx, hasChunk, nextChunk;
    if (examRewindDate > pillarChunk.startDate) {
      startChunkIdx = pivotChunkIdx ? pivotChunkIdx + 1 : pivotChunkIdx;
      hasChunk = () => startChunkIdx < chunks.length;
      nextChunk = () => startChunkIdx++;
    } else {
      startChunkIdx = pivotChunkIdx ? pivotChunkIdx - 1 : pivotChunkIdx;
      hasChunk = () => startChunkIdx >= 0;
      nextChunk = () => startChunkIdx--;
    }

    while (hasChunk()) {
      const chunkIdx = nextChunk();
      const { startDate: chunkStartDate, duration: chunkDuration } = chunks[
        chunkIdx
        ];
      if (
        examRewindDate >= chunkStartDate &&
        examRewindDate <= new Date(chunkStartDate.getTime() + chunkDuration)
      ) {
        return {
          chunk: chunkIdx,
          positionInChunk:
            (examRewindDate - chunkStartDate.getTime()) / 1000
        };
      }
    }

    return null;
  }

  /**
   * Find a position in a chunk
   * @param {Array} chunks - all known video chunks
   * @param {Date} examRewindDate - a rewind date
   * @returns {Number} a found position in a chunk or 0 otherwise
   */
  findPositionInChunk(chunks, examRewindDate) {
    if (chunks && chunks.length) {
      const {
        duration: firstChunkDuration,
        startDate: firstChunkStartDate
      } = chunks[0];
      const positionDuration =
        examRewindDate - firstChunkStartDate.getTime();
      if (positionDuration > firstChunkDuration) {
        return 0;
      }
      return positionDuration / 1000;
    }
    return 0;
  }

  /**
   * Handle a video error
   * @param {String} name - a name of a video player
   * @param {Number} playerIndex - a player index
   * @param {Promise} playerReadyState - a player ready state promise
   */
  onVideoError(name, playerIndex, playerReadyState = null) {
    playerReadyState && playerReadyState.reject();
    this.videoPlayerStore.setData({
      [name]: {
        ...this.videoPlayerStore.videoData[name],
        [`error_${playerIndex}`]: 'Video playback failed.'
      }
    });
  }

  /**
   * Find a position of an incident in a time line
   * @param {String|Number} incidentCreatedAt - an incident created date|time
   * @returns {Object} an incident position and date in a time line
   */
  findIncidentPosition(incidentCreatedAt) {
    const examStartedDate = new Date(this.examStarted);
    if (this.isLive()) {
      const examCompletedDate = this.examCompleted
        ? null
        : new Date();
      const examDuration =
        !examCompletedDate
          ? this.videoDuration
          : (examCompletedDate.getTime() - examStartedDate.getTime() - this.videoPlayerStore.videoData.videoDiscrepancy);
      const incidentCreatedDate = new Date(incidentCreatedAt);
      const shiftedIncidentCreatedDate = new Date(incidentCreatedDate.getTime() - incidentTimeShift);
      const usedIncidentCreatedDate =
        shiftedIncidentCreatedDate >= examStartedDate
          ? shiftedIncidentCreatedDate
          : incidentCreatedDate;

      return usedIncidentCreatedDate >= examStartedDate &&
      usedIncidentCreatedDate <= examCompletedDate
        ? {
          position:
            (usedIncidentCreatedDate.getTime() - examStartedDate.getTime()) /
            examDuration *
            100,
          date: usedIncidentCreatedDate
        }
        : null;    } else {
      return {
        position: (incidentCreatedAt / this.videoDuration) * 100,
        date: new Date(examStartedDate.getTime() + incidentCreatedAt)
      };
    }
  }

  /**
   * Gets player ready state
   * @returns Promise
   */
  getPlayerReadyState(name) {
    return this[`${name}ReadyState`];
  }

  /**
   * Updates video player store
   * @param {Object} videoState - new state
   */
  updateStore(videoState) {
    this.videoPlayerStore.setData(videoState);
  }

  /**
   * Format duration time for live stream
   * @param {Number} duration - live stream duration
   */
  getLiveExamDuration(duration) {
    this.videoPlayerStore.setData({ liveDurationTime: duration });
    this.liveTimestampInterval = setInterval(() => {
      this.videoPlayerStore.setData({
        liveDurationTime: ++this.videoPlayerStore.videoData.liveDurationTime
      });
    }, 1000);
  }
}

export default VideoPlayerController;
