import PrechecksBodyTitle from './PrechecksBodyTitle';

const PrechecksHeaderGroup = ({
  image,
  icon,
  iconPrefix,
  title,
  subtitle,
  color,
}) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="mt-2">
          <img
            src={image.src}
            alt={image.altText}
            className="precheck-header-img"
          />
        </div>
        <PrechecksBodyTitle
          title={title}
          subtitle={subtitle}
          icon={icon}
          iconPrefix={iconPrefix}
          color={color}
        />
      </div>
    </div>
  );
};

PrechecksHeaderGroup.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

PrechecksHeaderGroup.defaultProps = {
  image: {
    src: '',
    altText: 'An image',
  },
  title: '',
  subtitle: '',
};

export default PrechecksHeaderGroup;
