import React from 'react';

import { useMySessionsContext } from '../context';
import { ReservationActions } from './ReservationActions';
import { ReservationListItem } from './ReservationListItem';

/**
 * @typedef UnscheduledExamsProps
 * @property {string} studentReservationUrl
 * @property {string} studentOrderPath
 * @property {number[]} cancellationRequiredIds
 * @property {number} advanceCancellationReasonId
 * @property {boolean} isSupportedPlatform
 * @property {boolean} isDevelopmentEnv
 * @property {string} timezone
 */

/**
 * UnscheduledExams component displays a list of unscheduled exam reservations.
 * @param {UnscheduledExamsProps} props
 * @returns {React.ReactNode}
 */
export const UnscheduledExams = ({
  studentReservationUrl,
  studentOrderPath,
  cancellationRequiredIds,
  advanceCancellationReasonId,
  isSupportedPlatform,
  isDevelopmentEnv,
  timezone,
}) => {
  const { unscheduledReservations } = useMySessionsContext();

  if (!unscheduledReservations || unscheduledReservations.length === 0) {
    return <></>;
  }

  return (
    <div className="card" data-testid="unscheduled-exams">
      <div className="card-header bg-dark text-white">
        <div className="fs-5 font-weight-bold">
          {polyglot.t('my_sessions_v2_unscheduled_exams')}
        </div>
      </div>

      <ul className="list-group list-group-flush border">
        {unscheduledReservations.map((reservation) => (
          <li className="list-group-item" key={reservation.id}>
            <ReservationListItem
              reservation={reservation}
              studentReservationUrl={studentReservationUrl}
              studentOrderPath={studentOrderPath}
              cancellationRequiredIds={cancellationRequiredIds}
              isSupportedPlatform={isSupportedPlatform}
              isDevelopmentEnv={isDevelopmentEnv}
              timezone={timezone}
            >
              <ReservationActions
                reservation={reservation}
                studentReservationUrl={studentReservationUrl}
                studentOrderPath={studentOrderPath}
                cancellationRequiredIds={cancellationRequiredIds}
                advanceCancellationReasonId={advanceCancellationReasonId}
                isSupportedPlatform={isSupportedPlatform}
                isDevelopmentEnv={isDevelopmentEnv}
                timezone={timezone}
              />
            </ReservationListItem>
          </li>
        ))}
      </ul>
    </div>
  );
};
