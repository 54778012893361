import React from 'react';

/**
 * StudentNavigation component is the secondary navbar for student layout.
 * This is to be viewed in the desktop (screen size more than medium)
 *
 * @param {StudentNavigationProps} props
 * @returns {React.ReactNode}
 */
export function StudentNavigation({ studentNavigation }) {
  return (
    <nav className="navbar navbar-secondary navbar-expand-lg navbar-light bg-white border-bottom d-none d-md-block">
      <div className="container">
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <a
                className={`nav-link text-dark ${studentNavigation.isMySessionActive ? 'active' : ''}`}
                href={studentNavigation.mySessionsNavLink}
              >
                {polyglot.t('navbar_student_navigation_my_sessions')}
              </a>
            </li>

            <li className="nav-item">
              <a
                className={`nav-link text-dark ${studentNavigation.isMyOrderActive ? 'active' : ''}`}
                href={studentNavigation.myOrderNavLink}
              >
                {polyglot.t('navbar_student_navigation_my_orders')}
              </a>
            </li>

            <li className="nav-item">
              <a
                className={`nav-link text-dark`}
                href={studentNavigation.supportNavLink}
                target="_blank"
              >
                {polyglot.t('navbar_student_navigation_support')}
              </a>
            </li>
          </ul>
          <ul className="navbar-nav mr-left">
            {studentNavigation.hasBalance && (
              <li className="nav-item navbar-text mr-3 text-dark bg-light rounded-lg p-2 border">
                {`${polyglot.t('navbar_student_navigation_balance')}: ${studentNavigation.accountBalance}`}
              </li>
            )}

            <li className="nav-item">
              <a
                href={studentNavigation.studentOrderPath}
                className="nav-link text-dark"
              >
                <span className="mr-1">
                  {polyglot.t('navbar_student_navigation_my_cart')}
                </span>
                <span className="badge badge-pill badge-secondary">
                  {studentNavigation.cartQuantitySize}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
