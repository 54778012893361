import $ from 'jquery';
import DOMPurify from 'dompurify';

class Summernote {
  constructor(selector) {
    this.selector = $(selector);
  }

  getDefaultOptions() {
    return {
      toolbar: [
        ['style', ['style']],
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['para', ['ul', 'ol']],
        ['insert', ['link']],
        ['help', ['help']],
        ['view', ['fullscreen', 'codeview']],
      ],
      height: 200,
      disableDragAndDrop: true,

      codeviewFilter: true,
      codeviewFilterRegex:
        /<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|style)|t(?:itle|extarea)|xml)[^>]*?>/gi,
      codeviewIframeFilter: true,

      // When the user pastes, sanitize the content
      onpaste: function (e) {
        const thisNote = $(this);
        setTimeout(() => {
          const original = thisNote.code(); // Summernote's .code() in codeview
          const cleaned = DOMPurify.sanitize(original); // Use DOMPurify
          if (original !== cleaned) {
            thisNote.code('').html(cleaned);
          }
        }, 10);
      },

      callbacks: {
        // Fired when user blurs from Code View (i.e. toggles back to Normal View)
        onBlurCodeview: function (e) {
          const thisNote = $(this);
          const dirtyCode = thisNote.summernote('code');
          // Sanitize with DOMPurify before rendering in Normal View
          const cleanCode = DOMPurify.sanitize(dirtyCode);
          if (dirtyCode !== cleanCode) {
            thisNote.summernote('code', cleanCode);
          }
        },
      },
    };
  }

  init(includeFPClasses = false) {
    let summernoteOptions = this.getDefaultOptions();

    if (includeFPClasses) {
      summernoteOptions.styleTags = [
        {
          title: 'Normal',
          tag: 'div',
          className: 'flightpath-regular-text',
          value: 'div',
        },
        {
          title: 'Important',
          tag: 'p',
          className: 'flightpath-important',
          value: 'p',
        },
        {
          title: 'Unique',
          tag: 'p',
          className: 'flightpath-unique',
          value: 'p',
        },
        {
          title: 'Standard',
          tag: 'p',
          className: 'flightpath-standard',
          value: 'p',
        },
        {
          title: 'Proctor Action',
          tag: 'p',
          className: 'flightpath-proctor-action',
          value: 'p',
        },
      ];
    }

    this.selector.summernote(summernoteOptions);

    this.selector.on('summernote.enter', function (we, e) {
      $(this).summernote('pasteHTML', '<br><br>');
      e.preventDefault();
    });
  }

  /**
   * If you want a helper for other places you sanitize HTML,
   * just use DOMPurify directly here.
   */
  cleanPastedHTML(input) {
    return DOMPurify.sanitize(input);
  }
}

export default Summernote;
