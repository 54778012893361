import React, { useMemo, useState } from 'react';

import { formatDateTime, is24HourFormat } from '../../Shared/DateTimeFormatter';

const BUTTON_TIME_FORMAT = 'h:mm A';
const BUTTON_24HR_TIME_FORMAT = 'HH:mm';
const DATE_FORMAT = 'MMM Do';
const HEADER_DATE_FORMAT = 'MMMM D';
const SLOT_TIME_FORMAT = 'h:mm A z';
const SLOT_24HR_TIME_FORMAT = 'HH:mm z';

/**
 * @typedef {Object} Slot
 * @property {string} time - ISO8601 formatted time string
 * @property {string} timeShort - Localized short time format
 * @property {Object} form_data - Form method data
 * @property {string} form_data.method - Always 'post'
 * @property {string} form_data._method - Either 'post' or 'patch'
 * @property {Record<string, string|null>} form_params - Form parameters including reservation fields
 * @property {string} submit_url - URL for form submission
 * @param {string} timezone - The timezone (e.g., 'America/New_York')
 */

/**
 * Creates and submits a form with the given slot data
 * @param {Slot} slot - The slot containing form data
 */
function submitSlotForm(slot) {
  const form = document.createElement('form');
  form.setAttribute('method', slot.form_data.method.toLowerCase());
  form.action = slot.submit_url;

  // Add CSRF token
  const csrfToken = document.querySelector('meta[name="csrf-token"]')?.content;
  if (csrfToken) {
    const csrfInput = document.createElement('input');
    csrfInput.type = 'hidden';
    csrfInput.name = 'authenticity_token';
    csrfInput.value = csrfToken;
    form.appendChild(csrfInput);
  }

  // Add method override if needed
  if (slot.form_data?._method) {
    const methodInput = document.createElement('input');
    methodInput.type = 'hidden';
    methodInput.name = '_method';
    methodInput.value = slot.form_data._method;
    form.appendChild(methodInput);
  }

  // Add form params
  Object.entries(slot.form_params || {}).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = value;
      form.appendChild(input);
    }
  });

  document.body.appendChild(form);
  form.submit();
}

/**
 * @typedef {Object} SlotsCardProps
 * @property {Array<Slot>} slots
 */

/**
 * @param {SlotsCardProps} props
 * @returns {React.ReactNode}
 */
export default function SlotsCard({ slots = [] }) {
  const [selectedSlot, setSelectedSlot] = useState(null);
  const is24HrFormat = is24HourFormat();

  const groupedSlots = useMemo(() => {
    return slots.reduce((acc, slot) => {
      const date = formatDateTime(slot.time, HEADER_DATE_FORMAT, slot.timezone);
      slot.timeShort = formatDateTime(
        slot.time,
        is24HrFormat ? SLOT_24HR_TIME_FORMAT : SLOT_TIME_FORMAT,
        slot.timezone,
      );
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(slot);
      return acc;
    }, {});
  }, [slots]);

  const handleScheduleSlot = (slot) => {
    submitSlotForm(slot);
  };

  if (slots.length === 0) {
    return <UnavailableMessage />;
  }

  return (
    <>
      <div className="card mb-4">
        <div
          className="list-group list-group-flush"
          style={{ maxHeight: '400px', overflowY: 'auto' }}
        >
          {Object.entries(groupedSlots).map(([date, dateSlots]) => (
            <DateGroup
              key={date}
              date={date}
              slots={dateSlots}
              selectedSlot={selectedSlot}
              onSlotSelect={setSelectedSlot}
            />
          ))}
        </div>
      </div>

      <ScheduleButton
        selectedSlot={selectedSlot}
        onSchedule={handleScheduleSlot}
      />
    </>
  );
}

/**
 * @typedef {Object} DateGroupProps
 * @property {string} date
 * @property {Array<Slot>} slots
 * @property {Slot | null} selectedSlot
 * @property {(slot: Slot) => void} onSlotSelect
 */

/**
 * @param {DateGroupProps} props
 */
export function DateGroup({ date, slots, selectedSlot, onSlotSelect }) {
  return (
    <div
      role="region"
      aria-label={polyglot.t('time_slots_available_times', { date })}
    >
      <div
        className="list-group-item text-left font-weight-bold text-muted fs-4 px-4"
        style={{
          borderLeft: 'none',
          borderRight: 'none',
          borderTop: 'none',
        }}
        role="heading"
        aria-level="2"
      >
        {date}
      </div>
      <div
        role="listbox"
        aria-label={polyglot.t('time_slots_available_times', { date })}
        tabIndex={0}
      >
        {slots.map((slot) => (
          <div key={slot.time} style={{ position: 'relative' }}>
            {selectedSlot?.time === slot.time && (
              <div
                style={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  bottom: 0,
                  width: '8px',
                  backgroundColor: '#0072B1',
                  opacity: 0.5,
                  zIndex: 2,
                }}
                aria-hidden="true"
              />
            )}
            <button
              className="list-group-item list-group-item-action text-left px-4"
              style={{
                backgroundColor:
                  selectedSlot?.time === slot.time ? '#E6F3F9' : '',
                color: selectedSlot?.time === slot.time ? '#0072B1' : '',
                position: 'relative',
                zIndex: 1,
                borderLeft: 'none',
                borderRight: 'none',
                borderTop: 'none',
              }}
              onClick={() => onSlotSelect(slot)}
              aria-selected={selectedSlot?.time === slot.time}
              aria-label={polyglot.t('time_slots_select_time_slot', {
                time: slot.timeShort,
                date: date,
              })}
              role="option"
            >
              {slot.timeShort}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

/**
 * @typedef {Object} ScheduleButtonProps
 * @property {Slot | null} selectedSlot
 * @property {(slot: Slot) => void} onSchedule
 */

/**
 * @param {ScheduleButtonProps} props
 */
export function ScheduleButton({ selectedSlot, onSchedule }) {
  const { buttonText, formattedDate, formattedTime } = React.useMemo(() => {
    if (!selectedSlot?.time) {
      return {
        buttonText: polyglot.t('time_slots_select_time'),
        formattedDate: null,
        formattedTime: null,
      };
    }
    const is24HrFormat = is24HourFormat();
    const formattedDate = formatDateTime(
      selectedSlot.time,
      DATE_FORMAT,
      selectedSlot.timezone,
    );
    const formattedTime = formatDateTime(
      selectedSlot.time,
      is24HrFormat ? BUTTON_24HR_TIME_FORMAT : BUTTON_TIME_FORMAT,
      selectedSlot.timezone,
    );

    return {
      buttonText: polyglot.t('time_slots_schedule_button', {
        date: formattedDate,
        time: formattedTime,
      }),
      formattedDate,
      formattedTime,
    };
  }, [selectedSlot?.time]);

  return (
    <div className="text-center">
      <button
        className="btn btn-primary fs-5 font-weight-bold"
        disabled={!selectedSlot}
        onClick={() => selectedSlot && onSchedule(selectedSlot)}
        aria-label={
          selectedSlot
            ? polyglot.t('time_slots_schedule_appointment_aria', {
                date: formattedDate,
                time: formattedTime,
              })
            : polyglot.t('time_slots_select_time_aria')
        }
      >
        {buttonText}
      </button>
    </div>
  );
}

export function UnavailableMessage() {
  return (
    <div className="unavailable">
      <div className="callout callout-danger">
        <span className="fa fa-exclamation-triangle mr-2" />
        <strong>{polyglot.t('time_slots_unavailable_message')}</strong>
      </div>
    </div>
  );
}
