// BISummaryDashboard
//
// This class handles initializing the Summary Dashboard view
// retrieved from Tableau
import { loadPowerBIScript } from '../../utils/loadPowerBIScript.js'

const CLIENT_PORTAL = {
  dataset_id: '6a320470-befe-4e18-92db-cebc2137b0fa',
  report_id: '487f1455-e968-42b1-9fc2-7b88526387e8'
}

const SINGLE_USER = {
  dataset_id: '4c9d6917-e6bf-47e3-b7d5-0597e330c5d7',
  report_id: 'ef931fdf-f43c-406a-a225-7676e3da2c1a'
}

class BISummaryDashboard {
  constructor() {
    this.containerDiv = document.getElementById('vizContainer');
    // Get the value of power_bi_flipper from the hidden field and set usePowerBI
    this.usePowerBI = true;
    this.scriptLoaded = false;
  }

  async init() {
    if (this.usePowerBI) {
      await this.loadPowerBIDashboard();
    }
  }

  async loadPowerBIDashboard() {
    loadPowerBIScript(this.scriptLoaded)
      .then(async () => {
        this.scriptLoaded = true;
        const models = window['powerbi-client'].models;

        const iterationId = document.getElementById('iteration_uuid').value;
        const institutionId = document.getElementById('institution_uuid').value;
        const embedContainer = document.getElementById("embedContainer");
        const report_request_data = embedContainer.dataset.dashboardType === 'single-user'
          ? SINGLE_USER : CLIENT_PORTAL

        try {
          const response = await fetch(
            `/api/power_bi/get_embed_data?institution_uuid=${institutionId}&user_id=&iteration_uuid=${iterationId}&dashboard_type=summary_dashboards&report_id=${report_request_data.report_id}&dataset_id=${report_request_data.dataset_id}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
              },
            }
          );

          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          }

          const data = await response.json();
          let embedConfig = {
            type: "report",
            tokenType: models.TokenType.Embed,
            accessToken: data.embed_token,
            embedUrl: data.embed_url,
            id: report_request_data.report_id,
            permissions: models.Permissions.View,
            settings: {
              filterPaneEnabled: false,
              navContentPaneEnabled: true,
            },
          };

          powerbi.embed(embedContainer, embedConfig);
          embedContainer.addEventListener('embed-error', (event) => {
            console.error('Power BI Embed Error:', event.detail);
          });
        } catch (error) {
          console.error("Fetch error:", error.message);
        }
      })
      .catch((error) => {
        console.error('Error embedding Power BI report:', error);
      });
  }

  loadTableauDashboard() {
    const { width, height } = this.getContainerDimensions();
    const url = this.getDashboardUrl();

    const options = {
      hideTabs: false,
      hideToolbar: true,
      width: width + 'px',
      height: height + 'px',
      onFirstInteractive: () => {}
    };

    if (typeof tableau === 'undefined' || typeof tableau.Viz === 'undefined') {
      location.reload();
    } else {
      new tableau.Viz(this.containerDiv, url, options);
    }
  }

  getContainerDimensions() {
    return {
      width: this.containerDiv.offsetWidth,
      height: this.containerDiv.offsetHeight
    };
  }

  getDashboardUrl() {
    const iterationId = document.getElementById('iteration_uuid').value;
    const institutionId = document.getElementById('institution_uuid').value;
    const authorizedTicket = document.getElementById('authorized_ticket').value;

    return (
      `https://tableau-ext.proctoru.com` +
      `/trusted/${authorizedTicket}` +
      `${this.getDashboardType()}` +
      `?Institution UUID=${institutionId}` +
      `&Iteration UUID=${iterationId}`
    );
  }

  getDashboardType() {
    return this.containerDiv.dataset.dashboardType === 'single-user'
      ? '/views/ClientPortal-SingleUserv2/SingleUserExperience'
      : '/views/ClientPortal/Summary';
  }
}

export default BISummaryDashboard;

