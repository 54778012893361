import React from 'react';

/**
 * @typedef ExamPageHeaderProps
 * @property {string} selectStudentExamsPath
 */

/**
 * @param {ExamPageHeaderProps} props
 * @returns {React.ReactNode}
 */
export const ExamPageHeader = ({ selectStudentExamsPath }) => {
  return (
    <div className="page-header-parent row">
      <h1 className="col-md-6 page-header">
        {polyglot.t('my_sessions_v2_header_my_sessions')}
      </h1>
      <div className="col-md-6 schedule-exam-btn-parent">
        <a href={selectStudentExamsPath} className="btn btn-primary">
          <i className="fa fa-add mr-1"></i>
          {polyglot.t('my_sessions_v2_header_schedule_exam')}
        </a>
      </div>
    </div>
  );
};
