import React, { useMemo } from 'react';

import useCountdown from '../../../../hooks/useCountdown';
import { useMySessionsContext } from '../context';
import Countdown from './Countdown';
import { ReservationActions } from './ReservationActions';
import { ReservationListItem } from './ReservationListItem';

/**
 * @typedef ScheduledExamsProps
 * @property {string} studentReservationUrl
 * @property {string} studentOrderPath
 * @property {number[]} cancellationRequiredIds
 * @property {number} advanceCancellationReasonId
 * @property {boolean} isSupportedPlatform
 * @property {boolean} isDevelopmentEnv
 * @property {string} timezone
 */

/**
 * ScheduledExams component displays a list of scheduled exam reservations and a
 * countdown to the closest upcoming exam reservation.
 * @param {ScheduledExamsProps} props
 * @returns {React.ReactNode}
 */
export function ScheduledExams({
  studentReservationUrl,
  studentOrderPath,
  cancellationRequiredIds,
  advanceCancellationReasonId,
  isSupportedPlatform,
  isDevelopmentEnv,
  timezone,
}) {
  const { scheduledReservations } = useMySessionsContext();

  const nextReservation = scheduledReservations[0];

  const nextAccessibleTimeString = nextReservation?.fulfillment.adjustedDate;
  const nextAccessibleTime = useMemo(
    () => Date.parse(nextAccessibleTimeString),
    [nextAccessibleTimeString],
  );
  const timeLeftUntilNextAccessible = useCountdown(nextAccessibleTime);

  return (
    <div className="card border-0" data-testid="scheduled-exams">
      {/* Card Header */}
      <div className="card-header bg-dark text-white">
        {nextReservation ? (
          <div className="d-flex flex-wrap">
            <strong>
              {/* For 'small' and narrower screens... */}
              <span className="d-md-none fs-5 mr-1 mr-md-3">
                {polyglot.t('next_exam')}
              </span>

              {/* For 'medium' and wider screens... */}
              <span className="d-none d-md-block fs-5 mr-1 mr-md-3">
                {polyglot.t('your_next_exam_starts_in')}
              </span>
            </strong>

            <Countdown timeLeft={timeLeftUntilNextAccessible} />
          </div>
        ) : (
          <strong className="fs-5">{polyglot.t('exams')}</strong>
        )}
      </div>

      {/* Card Body */}
      {nextReservation ? (
        <ul className="list-group list-group-flush border">
          {scheduledReservations.map((reservation) => (
            <li className="list-group-item" key={reservation.id}>
              <ReservationListItem
                reservation={reservation}
                studentReservationUrl={studentReservationUrl}
                studentOrderPath={studentOrderPath}
                cancellationRequiredIds={cancellationRequiredIds}
                isSupportedPlatform={isSupportedPlatform}
                isDevelopmentEnv={isDevelopmentEnv}
                timezone={timezone}
              >
                <ReservationActions
                  reservation={reservation}
                  studentReservationUrl={studentReservationUrl}
                  studentOrderPath={studentOrderPath}
                  cancellationRequiredIds={cancellationRequiredIds}
                  advanceCancellationReasonId={advanceCancellationReasonId}
                  isSupportedPlatform={isSupportedPlatform}
                  isDevelopmentEnv={isDevelopmentEnv}
                  timezone={timezone}
                />
              </ReservationListItem>
            </li>
          ))}
        </ul>
      ) : (
        <div className="p-3 text-center text-muted">
          {polyglot.t('no_exams_scheduled')}
        </div>
      )}
    </div>
  );
}
