import React from 'react';
import moment from 'moment';

const TIME_FORMATS = {
  default: { hours: 12, dateFormat: 'hh:mmA', showPeriod: true },
  de: { hours: 24, dateFormat: 'HH:mm', showPeriod: false },
};

/**
 * Generates options for hours or minutes.
 *
 * @param {number} count - The total number of options to generate.
 * @param {boolean} [is12HourFormat=false] - Whether the format is 12-hour.
 * @returns {Array<{ value: number, label: string }>} - Array of options.
 */
const generateOptions = (count, is12HourFormat = false) => {
  if (is12HourFormat) {
    return Array.from({ length: count }, (_, i) => ({
      value: (i % count) + 1,
      label: ((i % count) + 1).toString().padStart(2, '0'),
    }));
  }

  return Array.from({ length: count }, (_, i) => ({
    value: i,
    label: i.toString().padStart(2, '0'),
  }));
};

/**
 * Converts a 24-hour format hour to 12-hour format.
 *
 * @param {number} hour24 - The hour in 24-hour format.
 * @returns {{ hour12: number, period: 'AM' | 'PM' }} - The converted 12-hour format hour and period.
 */
const convertTo12HourFormat = (hour24) => {
  const formattedTime = moment({ hour: hour24 }).format('hh A'); // Zero-padded hour + AM/PM
  const [hour12, period] = formattedTime.split(' ');
  return { hour12: parseInt(hour12, 10), period };
};

/**
 * Converts a 12-hour format hour to 24-hour format based on the period.
 *
 * @param {number} hour12 - The hour in 12-hour format.
 * @param {'AM' | 'PM'} period - The period (AM/PM).
 * @returns {number} - The converted 24-hour format hour.
 */
const convertTo24HourFormat = (hour12, period) => {
  return moment(`${hour12} ${period}`, 'hh A').hours(); // Get 24-hour format directly
};

/**
 * @typedef {Object} TimePickerProps
 *
 * @property {string} [time] The initial time value
 * @property {string} [dateFormat] A format string you want to apply to the time before onChange is called
 * @property {(value: string) => void} onChange The function to call when the time changes
 * @property {string} [userLang] The user's locale, e.g., 'de', 'en', 'es', etc.
 */

/**
 * A customizable TimePicker component supporting both 12-hour and 24-hour formats.
 *
 * @param {TimePickerProps} props The props for the component
 * @returns {React.ReactElement}
 */
export default function TimePicker({
  time,
  dateFormat,
  onChange,
  userLang = 'default',
}) {
  const config = TIME_FORMATS[userLang] || TIME_FORMATS.default;
  const { hours, dateFormat: internalFormat, showPeriod } = config;

  const displayTime = moment(time ?? '', internalFormat);
  const hour24 = displayTime.hours();
  const { hour12, period } = convertTo12HourFormat(hour24);
  const minute = displayTime.minutes();

  const hourOptions = React.useMemo(
    () => generateOptions(hours, hours === 12),
    [hours],
  );

  const minuteOptions = React.useMemo(() => generateOptions(60), []);

  const handleSelect = (h, m, p = period) => {
    const adjustedHour =
      hours === 12
        ? convertTo24HourFormat(h.toString().padStart(2, '0'), p)
        : h.toString().padStart(2, '0');
    const formattedTime = moment({
      hour: adjustedHour,
      minute: m.toString().padStart(2, '0'),
    }).format(dateFormat);
    onChange?.(formattedTime);
  };

  const handleHourChange = (e) =>
    handleSelect(parseInt(e.target.value, 10), minute);

  const handleMinuteChange = (e) =>
    handleSelect(hours === 12 ? hour12 : hour24, parseInt(e.target.value, 10));

  const handlePeriodChange = (newPeriod) => {
    handleSelect(hour12, minute, newPeriod);
  };

  return (
    <div className="timepicker">
      <div className="d-flex justify-content-center align-content-center flex-wrap time-select-container">
        <div className={`d-flex ${hours === 12 ? 'mr-4' : ''}`}>
          <select
            className="my-2 rounded-lg time-select"
            title="Select Hour"
            aria-label="Select Hour"
            onChange={handleHourChange}
            value={hours === 12 ? hour12 : hour24}
          >
            {hourOptions.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
          <span className="p-2 h4">:</span>
          <select
            className="my-2 rounded-lg time-select"
            title="Select Minute"
            aria-label="Select Minute"
            onChange={handleMinuteChange}
            value={minute}
          >
            {minuteOptions.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        {showPeriod && (
          <fieldset className="d-flex align-items-center select-time-period">
            <legend className="d-none">Time Period</legend>
            <input
              type="radio"
              id="timePeriodAM"
              name="timePeriod"
              value="AM"
              checked={period === 'AM'}
              onChange={() => handlePeriodChange('AM')}
            />
            <label htmlFor="timePeriodAM" class="mb-0">
              AM
            </label>
            <input
              type="radio"
              id="timePeriodPM"
              name="timePeriod"
              value="PM"
              checked={period === 'PM'}
              onChange={() => handlePeriodChange('PM')}
            />
            <label htmlFor="timePeriodPM" class="mb-0">
              PM
            </label>
          </fieldset>
        )}
      </div>
    </div>
  );
}
