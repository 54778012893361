import { useEffect, useState } from 'react';

import PrechecksHeaderGroup from '../CardSections/PrechecksHeaderGroup';
import useSupportChat from '../hooks/useSupportChat';

/**
 * @typedef {import('../../../types').PrecheckData} PrecheckData
 */

/**
 * @typedef AverageWaitTimeProps
 * @property {PrecheckData} precheckData
 * @property {boolean} extendedWait
 */

/**
 * @param {AverageWaitTimeProps} props
 * @returns {React.ReactNode}
 */
const AverageWaitTime = ({ precheckData, extendedWait }) => {
  return (
    <p className="mb-4">
      {polyglot.t('prechecks_examlobby_liveplus_average_wait_time_text')}
      <span
        className="precheck-dark rounded px-1"
        style={{
          backgroundColor: 'rgba(50, 124, 122, 0.1)',
          fontWeight: '500',
        }}
      >
        {extendedWait
          ? precheckData.extendedWaitTime
          : precheckData.averageWaitTime}
        {polyglot.t('prechecks_examlobby_liveplus_average_wait_time_minutes')}
      </span>
    </p>
  );
};

/**
 * @typedef {Object} ExamLobbyHeaderProps
 * @property {string} title
 * @property {string} icon
 * @property {string} iconPrefix
 * @property {PrecheckData} precheckData
 * @property {boolean} showBeginExamButton
 * @property {boolean} lmiConnected
 * @property {boolean} proctorConnected
 * @property {() => void} onExtremeWait
 * @property {() => void} onSupportChatOpen
 */

/**
 * @param {ExamLobbyHeaderProps} props
 * @returns {React.ReactNode}
 */
export default function ExamLobbyHeader({
  title,
  icon,
  iconPrefix,
  precheckData,
  showBeginExamButton,
  lmiConnected,
  proctorConnected,
  onExtremeWait,
  onSupportChatOpen,
}) {
  const { openMessenger } = useSupportChat();
  const [extendedWait, setExtendedWait] = useState(false);
  const [extremeWait, setExtremeWait] = useState(false);

  const deskImg = precheckData.images.deskImg;
  const waitingForProctor =
    !showBeginExamButton &&
    (lmiConnected || precheckData.integratedLmiChat) &&
    !proctorConnected;

  // Check if waiting time is longer than expected
  useEffect(() => {
    if (!waitingForProctor || !precheckData.averageWaitTime) return;

    const avgWaitTimeMs = precheckData.averageWaitTime * 60 * 1000;
    const id = setTimeout(() => {
      setExtendedWait(true);
    }, avgWaitTimeMs);

    // NOTE: For now we are setting a hard coded extreme wait time of 10 minutes
    const id2 = setTimeout(
      () => {
        onExtremeWait?.();
        setExtremeWait(true);
      },
      10 * 60 * 1000,
    );

    return () => {
      clearTimeout(id);
      clearTimeout(id2);
    };
  }, [waitingForProctor, precheckData.averageWaitTime, onExtremeWait]);

  // Check if we need to open the support chat
  useEffect(() => {
    const openChatScenarios = [
      // The user has been waiting for a very long time, so they will need support
      waitingForProctor && extremeWait,
    ];

    if (openChatScenarios.some((scenario) => scenario)) {
      onSupportChatOpen?.();
      openMessenger();
    }
  }, [waitingForProctor, extremeWait, onSupportChatOpen]);

  if (
    precheckData.livePlus &&
    !lmiConnected &&
    !precheckData.integratedLmiChat
  ) {
    return (
      <PrechecksHeaderGroup
        image={deskImg}
        iconPrefix={iconPrefix}
        icon="fa-exclamation-circle"
        title={polyglot.t('prechecks_examlobby_header_run_lmi_rescue')}
        color="precheck-text-warning"
      />
    );
  }

  if (waitingForProctor) {
    if (extremeWait) {
      return (
        <>
          <PrechecksHeaderGroup
            image={deskImg}
            iconPrefix={iconPrefix}
            icon="fa-exclamation-circle"
            title={polyglot.t(
              'prechecks_examlobby_header_connection_timed_out',
            )}
            color="precheck-text-danger"
          />
        </>
      );
    } else if (extendedWait) {
      return (
        <>
          <PrechecksHeaderGroup
            image={deskImg}
            iconPrefix={iconPrefix}
            icon="fa-circle-notch fa-spin"
            title={polyglot.t(
              'prechecks_examlobby_liveplus_title_extended_wait',
            )}
            color="precheck-dark"
          />
          <AverageWaitTime
            precheckData={precheckData}
            extendedWait={extendedWait}
          />
        </>
      );
    } else {
      return (
        <>
          <PrechecksHeaderGroup
            image={deskImg}
            iconPrefix={iconPrefix}
            icon="fa-circle-notch fa-spin"
            title={polyglot.t('prechecks_examlobby_liveplus_title_normal_wait')}
            color="precheck-dark"
          />
          <AverageWaitTime
            precheckData={precheckData}
            extendedWait={extendedWait}
          />
        </>
      );
    }
  }

  if (proctorConnected) {
    if (precheckData.integratedLmiChat) {
      return (
        <PrechecksHeaderGroup
          image={deskImg}
          iconPrefix={iconPrefix}
          icon="fa-check-circle"
          title={polyglot.t(
            'prechecks_examlobby_header_proctor_connected_via_chat',
          )}
          color="precheck-primary"
        />
      );
    } else {
      return (
        <PrechecksHeaderGroup
          image={deskImg}
          iconPrefix={iconPrefix}
          icon="fa-check-circle"
          title={polyglot.t(
            'prechecks_examlobby_header_proctor_connected_via_lmi_rescue',
          )}
          color="precheck-primary"
        />
      );
    }
  }

  if (showBeginExamButton) {
    return (
      <PrechecksHeaderGroup
        image={deskImg}
        iconPrefix={iconPrefix}
        icon={icon}
        title={polyglot.t('prechecks_examlobby_title')}
        color="precheck-primary"
      />
    );
  }

  return (
    <PrechecksHeaderGroup
      image={deskImg}
      iconPrefix={iconPrefix}
      icon={icon}
      title={title}
    />
  );
}
