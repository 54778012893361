import { Summernote } from './index';

class GlFlightPathProcedures {
  constructor() {
    this.glFlightPathProcsContainer = document.querySelector('#gl-procs');
    this.glFlightPathProcs = $('#gl-procs textarea');
    this.glFlightPathEditableContainer = document.getElementById(
      'enable-gl-flight-path-editable-container',
    );
  }

  init() {
    const glFlightPathEnabled = document.querySelector(
      '[data-enable-gl-flight-path-checkbox=true]',
    );

    this.fObject = JSON.parse(
      glFlightPathEnabled.getAttribute('data-f-object'),
    );

    if (
      this.fObject?.modelType === 'Department' ||
      this.fObject?.modelType === 'Iteration'
    ) {
      this.setupDepartmentOrIteration(glFlightPathEnabled);
    } else {
      this.setupDefault(glFlightPathEnabled);
    }
  }

  setupDepartmentOrIteration(glFlightPathEnabled) {
    const glFlightPathCustomized = document.querySelector(
      '[data-customize-gl-flight-path-checkbox=true]',
    );

    const toggleProcsContainerVisibility = () => {
      if (glFlightPathEnabled.checked) {
        this.glFlightPathProcsContainer.classList.remove('d-none');
      } else {
        this.glFlightPathProcsContainer.classList.add('d-none');
      }
    };

    const fetchAndUpdatePartial = () => {
      $.ajax({
        url: '/shared/attributes/dynamic_gl_flight_path_procedures',
        type: 'GET',
        data: {
          model_type: this.fObject.modelType,
          model_id: this.fObject.modelId,
          customize_gl_flight_path: glFlightPathCustomized.checked,
        },
        success: (data) => {
          this.glFlightPathProcsContainer.innerHTML = data;
        },
        error: (xhr, status, error) => {
          const errorMessage = `An error occurred: ${status} - ${error}`; // Corrected error message format
          console.error(errorMessage);
        },
      });
    };

    if (glFlightPathEnabled) {
      glFlightPathEnabled.addEventListener(
        'change',
        toggleProcsContainerVisibility,
      );
    }

    if (glFlightPathEnabled) {
      glFlightPathEnabled.addEventListener('change', fetchAndUpdatePartial);
    }

    if (glFlightPathCustomized) {
      glFlightPathCustomized.addEventListener('change', fetchAndUpdatePartial);
    }

    toggleProcsContainerVisibility(); // Initial check
  }

  setupDefault(glFlightPathEnabled) {
    const toggleEditableContainer = (show) => {
      this.glFlightPathProcsContainer.classList.toggle('d-none', !show);
      this.glFlightPathEditableContainer.classList.toggle('d-none', !show);
    };

    const setupEditableCheckbox = () => {
      const glFlightPathEditable = document.querySelector(
        '[data-enable-gl-flight-path-editable-checkbox=true]',
      );
      if (glFlightPathEditable) {
        glFlightPathEditable.addEventListener('change', (event) => {
          let isCheckboxChecked = event.target.checked;

          if (isCheckboxChecked) {
            $('input.gl-txt[readonly="readonly"]').removeAttr('readonly');
          } else {
            $('input.gl-txt').attr('readonly', 'readonly');
          }

          this.glFlightPathProcs.each((index, proc) => {
            $(proc).summernote(isCheckboxChecked ? 'enable' : 'disable');
          });
        });
      }
    };

    if (glFlightPathEnabled && this.glFlightPathProcs) {
      glFlightPathEnabled.addEventListener('change', (event) => {
        toggleEditableContainer(event.target.checked);
        if (event.target.checked) setupEditableCheckbox();
      });
    }

    if (glFlightPathEnabled.checked) {
      toggleEditableContainer(true);
      setupEditableCheckbox();
    }

    this.glFlightPathProcs.map((index, proc) => {
      const isEditable = $(proc).data('editable');
      if (!isEditable) {
        let summernoteOptions = new Summernote('').getDefaultOptions();
        $(proc).summernote(summernoteOptions);
        $(proc).summernote('disable');
      }
    });
  }
}

export default GlFlightPathProcedures;
