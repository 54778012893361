import React, { useState } from 'react';
import { useLanguage } from '../../../hooks/useLanguage';

/**
 * MobileNavigation component is to show the secondary menu items in the hamburger menu of primary navbar in
 * the mobile view.
 *
 * @param {MobileNavigationProps} props
 * @returns {React.ReactNode}
 */
export function MobileNavigation({ menuItems, navbar }) {
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);

  const { setPreferredLanguage } = useLanguage();

  const toggleLanguageDropdown = () => {
    setIsLanguageDropdownOpen((prevState) => !prevState);
  };

  return (
    <div className="mobile-navbar-v2">
      <span className="timezone">{navbar.timezone}</span>
      <ul>
        {menuItems.map(
          (menu, key) =>
            menu && (
              <li key={key}>
                {!!menu.link ? (
                  <a href={menu.link}>{menu.title}</a>
                ) : (
                  menu.title
                )}
                {menu.count !== undefined && (
                  <span className="count-badge">{menu.count}</span>
                )}
              </li>
            ),
        )}
      </ul>
      <hr />
      <ul>
        <li>
          <a href={navbar.editAccountPath}>
            {polyglot.t('navbar_account_settings')}
          </a>
        </li>
        {navbar.canEditPassword && (
          <li>
            <a href={navbar.editPasswordPath}>
              {polyglot.t('navbar_password_settings')}
            </a>
          </li>
        )}
        <li>
          <a
            href={
              navbar.isInstitutionUser
                ? navbar.notificationAccountPath
                : navbar.notificationStudentPrefPath
            }
          >
            {polyglot.t('navbar_notification_settings')}
          </a>
        </li>
        <li>
          <a href={navbar.logoutPath}>{polyglot.t('navbar_logout')}</a>
        </li>
      </ul>
      <hr />
      <ul>
        <li>
          <a onClick={toggleLanguageDropdown} tabIndex={0}>
            {polyglot.t('prechecks_language_dropdown_select')}{' '}
            <i className="fa fa-caret-down"></i>{' '}
          </a>

          {isLanguageDropdownOpen && (
            <ul>
              {navbar.availableLanguagesForUser.map((lang) => (
                <li
                  tabIndex={0}
                  key={lang.locale}
                  className="language-item"
                  onClick={() => {
                    setPreferredLanguage(lang.name);
                  }}
                >
                  {lang.name}
                </li>
              ))}
            </ul>
          )}
        </li>
      </ul>
    </div>
  );
}
