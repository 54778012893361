import PropTypes from 'prop-types';

class MetricsAccordion extends React.Component {
  constructor(props) {
    super(props);

    const { settings } = this.props;

    this.state = {
      expanded: !settings.status,
      data: this.parseResults(settings.results),
    };
  }

  componentDidMount() {
    this.bindCollapseButton();

    if (this.state.expanded) {
      $(`#${this.props.settings.groupTitle}Collapse`).collapse('show');
    }
  }

  bindCollapseButton() {
    const { settings } = this.props;
    $(`#${settings.groupTitle}_accordion`).on('hidden.bs.collapse', () => {
      this.setState({ expanded: false });
    });
    $(`#${settings.groupTitle}_accordion`).on('show.bs.collapse', () => {
      this.setState({ expanded: true });
    });
  }

  addHelpLink(result) {
    const { helpLinks } = this.props;
    const return_array = result.message.split(':').map((item) => item.trim());

    if (return_array.length <= 1) {
      return_array.push('');
    } else {
      return_array[0] = `${return_array[0]}`;
    }

    if (result.help_texts && Array.isArray(result.help_texts)) {
      return_array.push(result.help_texts);
    } else {
      return_array.push([]);
    }

    if (result.support_note) {
      return_array.push(result.support_note);
    } else {
      return_array.push('');
    }

    if (
      result.hasOwnProperty('type') &&
      helpLinks.hasOwnProperty(result.type) &&
      result.type == 'camera'
    ) {
      return_array.push(helpLinks[result.type]);
    }

    return return_array;
  }

  parseResults(raw_results) {
    const return_object = {
      failed: [],
      passed: [],
      recommended: [],
    };

    raw_results.forEach((result) => {
      if (result.message) {
        switch (result.style) {
          case 'text-danger':
            return_object.failed.push(this.addHelpLink(result));
            break;
          case 'text-warning':
            return_object.recommended.push(this.addHelpLink(result));
            break;
          case 'text-success':
            return_object.passed.push(this.addHelpLink(result));
            break;
          case null:
            return_object.passed.push(this.addHelpLink(result));
            break;
          default:
            // noop
            break;
        }
      }
    });

    return return_object;
  }

  render() {
    const { expanded, data } = this.state;
    const { settings } = this.props;
    const collapseId = `${settings.groupTitle}Collapse`;
    const heading = `${settings.groupTitle}Heading`;
    const settingsLabelId = `${settings.groupTitle}_aria_label`;

    /**
     * Gets a status indicator string that represents the system check results.
     * @note This string is used to determine which icon should be displayed.
     * @returns {'passed' | 'recommended' | 'failed' | 'no'}
     */
    const getStatus = () => {
      if (data.failed.length > 0) return 'failed';
      if (data.recommended.length > 0) return 'recommended';
      if (data.passed.length > 0) return 'passed';
      return 'no';
    };

    return (
      <div id={`${settings.groupTitle}_accordion`} className="mb-3">
        <div className="card">
          <div id={heading} className="card-body">
            <h4 className="card-title">
              <a
                className={!expanded ? 'collapsed' : ''}
                aria-controls={collapseId}
                aria-expanded={expanded}
                data-parent={`#${settings.groupTitle}_accordion`}
                data-toggle="collapse"
                href={`#${collapseId}`}
                role="button"
              >
                <img
                  src={settings.imagePath}
                  alt={`${settings.groupTitle} icon with ${getStatus()} status`}
                  height="25"
                />
                <span id={settingsLabelId} className="ml-3">
                  {polyglot.t(`${settings.groupTitle.toLowerCase()}_settings`)}
                </span>
              </a>
              <a
                className={`float-right fa ${
                  expanded ? 'fa-chevron-up' : 'fa-chevron-down'
                } mt-2 mr-3 ${expanded ? '' : 'collapsed'}`}
                aria-controls={collapseId}
                aria-expanded={expanded}
                aria-labelledby={settingsLabelId}
                data-parent={`#${settings.groupTitle}_accordion`}
                data-toggle="collapse"
                href={`#${collapseId}`}
                role="button"
              ></a>
            </h4>
          </div>
          <div
            id={collapseId}
            className="panel-collapse collapse"
            aria-labelledby={heading}
          >
            <div className="card-body">{this.renderResults()}</div>
          </div>
        </div>
      </div>
    );
  }

  renderResult(result, pass_fail, index) {
    const { settings } = this.props;

    return (
      <div
        className="mt-3 mb-3"
        key={`${settings.groupTitle}_${pass_fail}_${index}`}
      >
        <strong>{result[0]}</strong>
        <br />
        <span className="system-metric">{result[1]}</span>
        <br />
        {/* Render unordered list (if details exist) */}
        {result.length > 2 && result[2].length > 0 && (
          <ul style={{ paddingInlineStart: '30px', marginBottom: '0px' }}>
            {result[2].map((help_text, helpTextIndex) => (
              <li
                key={`helptext_${index}_${helpTextIndex}`}
                dangerouslySetInnerHTML={{ __html: help_text }}
              ></li>
            ))}
          </ul>
        )}
        {result.length > 3 && result[3].length > 0 && (
          <span style={{ fontSize: '0.9rem', fontStyle: 'italic' }}>
            {result[3]}
          </span>
        )}
        {result.length > 4 && ['failed', 'recommended'].includes(pass_fail) && (
          <>
            <span>{result[4].text}</span>
            <br />
            <a href={result[4].link} target="_blank">
              <strong> {result[4].link} </strong>
            </a>
          </>
        )}
      </div>
    );
  }

  renderResults() {
    const { data } = this.state;
    return (
      <div>
        {data.failed.length > 0 && (
          <>
            <div className="fs-5 mb-3">
              <div className="text-danger">
                <h5>{polyglot.t('tio_tests_failed')}</h5>
              </div>
            </div>
            <div>
              {data.failed.map((result, index) => {
                return this.renderResult(result, 'failed', index);
              })}
            </div>
          </>
        )}
        {data.recommended.length > 0 && (
          <>
            <div className="fs-5 mb-3">
              <div className="text-warning">
                {polyglot.t('tio_recommended_tests_failed')}
              </div>
            </div>
            <div>
              {data.recommended.map((result, index) => {
                return this.renderResult(result, 'recommended', index);
              })}
            </div>
          </>
        )}
        {data.passed.length > 0 && (
          <>
            <div className="fs-5 mb-3">
              <div className="text-success">
                <h5>{polyglot.t('tio_tests_passed')}</h5>
              </div>
            </div>
            <div>
              {data.passed.map((result, index) => {
                return this.renderResult(result, 'passed', index);
              })}
            </div>
          </>
        )}
      </div>
    );
  }
}

MetricsAccordion.propTypes = {
  settings: PropTypes.object.isRequired,
  helpLinks: PropTypes.object.isRequired,
};

export default MetricsAccordion;
