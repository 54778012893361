import CopernicusHelper from '../../utils/CopernicusHelper';

const STREAM_STATUS_UPDATE_TIMEOUT = 60000;

/**
 * Construct an instance of the helper
 * @param {Element} camVideo - video DOM element for a camera
 * @param {Element} screenVideo - video DOM element for screen sharing
 * @param {String} userId - a user identifier
 * @param {String} fulfillmentId - a fulfillment identifier
 * @param {String} streamHost - a stream host
 * @param {Function} socketErrorHandler - socket error handler
 * @param {Array} iceServers - ICE servers list
 * @param {VideoPlayerStore} videoPlayerStore - video player store
 * @param {String} region - AWS region for recording/chat
 */
export default class VideoPlayerLiveStreamHelper {
  constructor(
    camVideo,
    screenVideo,
    userId,
    userUuid,
    fulfillmentId,
    streamHost,
    socketErrorHandler,
    iceServers,
    stopTimer,
    videoPlayerStore,
    sessionUuid,
    videoLayoutSettings,
    videoRef,
    videoService,
    region
  ) {
    this.camVideo = camVideo;
    this.screenVideo = screenVideo;
    this.userId = userId;
    this.userUuid = userUuid;
    this.fulfillmentId = fulfillmentId;
    this.streamHost = streamHost;
    this.socketErrorHandler = socketErrorHandler;
    this.stopTimer = stopTimer;
    this.iceServers = iceServers;
    this.videoPlayerStore = videoPlayerStore;
    this.videoLayoutSettings = videoLayoutSettings;
    this.videoRef = videoRef;
    this.videoService = videoService;
    this.sessionUuid = sessionUuid;
    this.timers = {};
    this.cjs = null;
    this.region = region;
    this.liveStreamStarted = false;
  }

  /**
   * Setup a live stream helper
   */
  async setup() {
    const devices = {};
    const domElements = {
      webcam: this.camVideo,
      screen: this.screenVideo,
      secondCamera: null,
      audioOutput: null,
    };
    const callbacks = {};
    const options = {
      userID: this.userId,
      userUUID: this.userUuid,
      role: 'proctor',
      exam: this.fulfillmentId,
      domElements,
      mediaServerUrl: new URL(this.streamHost),
      videoServiceUrl: new URL(this.videoService),
      region: this.region,
      videoLayout: this.videoLayoutSettings.videoLayout,
      iceServers: this.iceServers,
      devices,
      browserTabId: window.tabId ? window.tabId : 0,
    };

    this.cjs = await CopernicusHelper.getInstance('VideoPlayer', options, callbacks);
    await this.cjs.startLiveStream(this.videoLayoutSettings);
    this.liveStreamStarted = true;
    this.cjs.startWebRtcStatsReporter();

    this.setStreamStatus('webcam', 'connected');
    this.setStreamStatus('screen', 'connected');
    this.startStreamStatusTimer('webcam');
    this.startStreamStatusTimer('screen');
  }

  /**
   * Start the live stream
   */
  async startStream() {
    await this.cjs.startLiveStream(this.videoLayoutSettings);
    this.setLiveStreamStarted(true);
  }

  /**
   * Stop the live stream
   */
  async stopStream() {
    await this.cjs.stopLiveStream();
    this.setLiveStreamStarted(false);
  }

  setLiveStreamStarted(isStarted = true) {
    this.liveStreamStarted = isStarted;
  }

  isLiveStreamStarted() {
    return this.liveStreamStarted;
  }

  /**
   * Shut down a live stream helper
   */
  shutdown() {
    if (this._shutdown) return;
    this._shutdown = true;

    void CopernicusHelper.closeInstance();
    this.stopStreamStatusTimer('webcam');
    this.stopStreamStatusTimer('screen');
    this.setStreamStatus('webcam', 'finished');
    this.setStreamStatus('screen', 'finished');
  }

  setStreamStatus(type, status) {
    switch (type) {
      case 'webcam':
        this.videoPlayerStore.setData({ cameraStatus: status });
        break;
      case 'screen':
        this.videoPlayerStore.setData({ screenStatus: status });
        break;
    }
  }

  stopStreamStatusTimer(type) {
    if (this.timers[type]) {
      clearTimeout(this.timers[type]);
      this.timers[type] = null;
    }
  }

  startStreamStatusTimer(type) {
    this.timers[type] = setTimeout(() => {
      this.setStreamStatus(type, 'error');
    }, STREAM_STATUS_UPDATE_TIMEOUT);
  }
}
