const PrechecksBodyTitle = ({
  title,
  subtitle,
  icon,
  iconPrefix,
  color = 'precheck-dark',
}) => {
  return (
    <div className="row my-3">
      <div className="col-md-12">
        <h1
          id="prechecks-body-title"
          className={`fs-5 ${color} mx-auto`}
          style={{ width: 'fit-content' }}
        >
          {icon && (
            <i
              className={`${iconPrefix} ${icon} fa-1x mr-2`}
              aria-hidden="true"
            />
          )}
          {title}
          {subtitle && (
            <span className="fs-6 d-block text-left">{subtitle}</span>
          )}
        </h1>
      </div>
    </div>
  );
};

PrechecksBodyTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

PrechecksBodyTitle.defaultProps = {
  title: 'Generic Precheck Title',
  subtitle: '',
};

export default PrechecksBodyTitle;
