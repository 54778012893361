import React, { useState } from 'react';

import { MacDownloadModal } from '../../../Exam/DownloadGuardianBrowser';

/** @typedef {import('../../../../types').GuardianDownloadUrls} GuardianDownloadUrls */
/** @typedef {import('../../../../types').GuardianPlatform} GuardianPlatform */

/**
 * @typedef DownloadButtonProps
 * @property {GuardianPlatform} guardianBrowserPlatform
 * @property {GuardianDownloadUrls} guardianBrowserDownloadUrls
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setShowGuardianMacDownload
 * @property {boolean} isGuardianExtensionEnabledForAny
 * @property {string} guardianExtensionUrl
 */

/**
 * @param {DownloadButtonProps} props
 * @returns {React.ReactNode}
 */
const DownloadButton = (props) => {
  /**
   * @typedef ActionLinkAttributes
   * @property {string} href
   * @property {React.MouseEventHandler} [onClick]
   */

  /** @returns {ActionLinkAttributes} */
  const getActionLinkAttributes = () => {
    if (props.isGuardianExtensionEnabledForAny) {
      return {
        href: props.guardianExtensionUrl,
      };
    }

    switch (props.guardianBrowserPlatform) {
      case 'WINDOWS':
        return {
          href: props.guardianBrowserDownloadUrls.windows,
        };
      case 'MAC':
        return {
          href: '#',
          onClick: (event) => {
            event.preventDefault();
            props.setShowGuardianMacDownload(true);
          },
        };
    }
  };

  const attributes = getActionLinkAttributes();

  if (!attributes) {
    return <></>;
  }

  return (
    <a
      className="btn btn-secondary action-btn mb-2 d-block d-sm-block d-md-inline mr-md-4"
      href={attributes.href}
      role="button"
      onClick={attributes.onClick}
    >
      <i className="fa fa-download action-btn-icon"></i>
      {polyglot.t('my_sessions_v2_action_links_download_guardian')}
    </a>
  );
};

/**
 * @typedef ActionLinksProps
 * @property {string | null} guardianExtensionUrl
 * @property {GuardianPlatform} guardianBrowserPlatform
 * @property {boolean} isSupportedPlatform
 * @property {GuardianDownloadUrls} guardianBrowserDownloadUrls
 * @property {string} testItOutUrl
 * @property {string} faqUrl
 * @property {boolean} isGuardianExtensionEnabledForAny
 */

/**
 * @param {ActionLinksProps} props
 * @returns {React.ReactNode}
 */
export const ActionLinks = (props) => {
  const [showGuardianMacDownload, setShowGuardianMacDownload] = useState(false);

  return (
    <div className="action-links">
      <p className="helper-text">
        {polyglot.t('my_sessions_v2_action_links_get_started_helper')}
      </p>
      <a
        className={`btn btn-secondary action-btn mb-2 ${
          props.isSupportedPlatform && 'd-block d-sm-block d-md-inline mr-md-4'
        }`}
        href={props.faqUrl}
        target="_blank"
      >
        <i className="fa fa-arrow-up-right-from-square action-btn-icon"></i>
        {polyglot.t('my_sessions_v2_action_links_guides_support')}
      </a>
      {props.isSupportedPlatform && (
        <>
          <a
            className="btn btn-secondary action-btn mb-2 d-block d-sm-block d-md-inline mr-md-4"
            href={props.testItOutUrl}
            target="_blank"
          >
            <i className="fa fa-laptop action-btn-icon"></i>
            {polyglot.t('my_sessions_v2_action_links_test_your_equipment')}
          </a>
          <DownloadButton
            guardianBrowserDownloadUrls={props.guardianBrowserDownloadUrls}
            guardianBrowserPlatform={props.guardianBrowserPlatform}
            setShowGuardianMacDownload={setShowGuardianMacDownload}
            guardianExtensionUrl={props.guardianExtensionUrl}
            isGuardianExtensionEnabledForAny={
              props.isGuardianExtensionEnabledForAny
            }
          />
        </>
      )}
      <MacDownloadModal
        guardianBrowserDownloadUrls={props.guardianBrowserDownloadUrls}
        showGuardianMacDownload={showGuardianMacDownload}
        setShowGuardianMacDownload={setShowGuardianMacDownload}
      />
    </div>
  );
};
