import TableLoader from'./Tables/TableLoader';
import IncidentReportExportButton from './Buttons/IncidentReportExportButton';
import IncidentReportFilter from './Filters/IncidentReportFilter';
import IncidentReportMoreOptionsButton from './Buttons/IncidentReportMoreOptionsButton';
import IncidentReportTable from './Tables/IncidentReportTable';
import Toast from '../../src/utils/Toast';

class IncidentReportApp extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      id: 0,
      incidentReports: [],
      incidentStatuses: [],
      incidentReportsCount: 0,
      urgencyData: {},
      proctorLocations: [],
      serviceTypes: [],
      gifPath: '',
      loading: false,
      blankMsg: 'Click Search to Find Incident Reports.',
      exportDisabled: false,
      page: 1,
      limit: 20,
      filterParams: {
        institution: '',
        department: props.department_name || '',
        exam: '',
        testTaker: '',
        urgencyLevel: '',
        startDate: '',
        endDate: '',
        status: ['IncidentReportCenter::IncidentReport::NewReportState'],
        archived: false,
        order: '',
        type: '',
        location_id: '',
        courseName: '',
        serviceType: '',
        instructor: '',
        dataRegion: ''
      },
    };

    let threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth()-3);
    this.state.filterParams['startDate'] = threeMonthsAgo.toLocaleDateString();

    this.state.filterParams['order'] = (this.id() == 0) ? '' : "recent" 
    this.updateInstitutionId = this.updateInstitutionId.bind(this);
    this.updateFilterState = this.updateFilterState.bind(this);
    this.updateStatusFilterState = this.updateStatusFilterState.bind(this);
    this.submitFilterForm = this.submitFilterForm.bind(this);
    this.goToPage = this.goToPage.bind(this);
    this.changePageLimit = this.changePageLimit.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
    this.sendExportFile = this.sendExportFile.bind(this);
  }

  componentDidMount() {
  }

  updateInstitutionId = (id) => {
    const filterParams = this.state.filterParams;
    filterParams['institution'] = id;
    this.setState({ filterParams: filterParams });
  }

  updateDataRegion = (region) => {
    const filterParams = this.state.filterParams;
    filterParams['dataRegion'] = region;
    this.setState({ filterParams: filterParams });
  }

  updateFilterState = (event) => {
    const id = event.currentTarget.id;
    const filterParams = this.state.filterParams;
        filterParams[id] = event.currentTarget.value;

    this.setState({ filterParams: filterParams });
  }

  updateSubtypeFilterState = (subtypes) => {
    const filterParams = this.state.filterParams
    filterParams.subtypes = subtypes

    this.setState({ filterParams: filterParams })
  }

  updateStatusFilterState = (statuses) => {
    const filterParams = this.state.filterParams
    filterParams.status = statuses

    this.setState({ filterParams: filterParams })
  }

  submitFilterForm = (event) => {
    event.preventDefault();
    $('#submit').prop('disabled',true);
    $('#data-region-warning-message').hide();

    const type = event.currentTarget.id;
    let params;

    if (type == 'reset') {
      params = {
        id: this.id(),
        page: 1,
        limit: 20
      };

      $('#select2-institution').val(null).trigger('change')
      $('#subtype').val(null).trigger('change')
      $('#status').val('IncidentReportCenter::IncidentReport::NewReportState').trigger('change')
    
      this.setState(
      (prevState) => ({
        filterParams: {
          institution: '',
          department: this.props.department_name ? this.props.department_name : '',
          exam: '',
          testTaker: '',
          urgencyLevel: 'All',
          startDate: '',
          endDate: '',
          status: ['IncidentReportCenter::IncidentReport::NewReportState'],
          archived: false,
          order: '',
          type: '',
          location_id: '',
          courseName: '',
          serviceType: '',
          instructor: '',
          dataRegion: '',
          },
        page: 1,
        blankMsg: 'No Incident Reports Found!',
        limit: 20,
      }),
        () => {
          params = {
            ...params,
            filterParams: { ...this.state.filterParams },
          };
         this.getIncidentReports(params);
        }
      );
    }else {

      params = {
        id: this.id(),
        filterParams: this.state.filterParams,
        page: 1,
        limit: this.state.limit
      };
      this.getIncidentReports(params);
    }
  }

  handleSuccess = (response, params) => {
    this.setState({
      incidentReports: response.incident_reports,
      incidentStatuses: response.incident_statuses,
      incidentReportsCount: response.incident_reports_count,
      proctorLocations: response.proctor_locations,
      serviceTypes: response.service_types,
      urgencyData: response.urgency_data,
      controller: response.controller,
      blankMsg: 'No Incident Reports Found!',
      gifPath: response.gif_path
    })
  }

  handleError = (response) => {
    $('#submit').prop('disabled',false);
    if(response.status == 504) {
      alert('The server encountered a temporary error and could not complete your request. Please try again in 30 seconds')
    }
    else {
      alert('Error ' + response.responseText);
    }
  }

  handleComplete = () => {
    $('#submit').prop('disabled',false);
    this.setState({ loading: false })
  }

  handleExportSuccess = (response) => {
    if (response.status == 'ok') {
      new Toast().success({
        message: response.message
      });
      this.setState({exportDisabled: false});
    }
  }

  handleExportError = (error) => {
    this.setState({exportDisabled: false});
    new Toast().danger({
      message: 'The server encountered a temporary error and could not complete your request. Please try again in 30 seconds'
    });
  }

  handleExportFilterError = () => {
    this.setState({exportDisabled: false});
    new Toast().danger({
      message: 'To generate a report with a duration greater than 90 days, you must select another filter option to generate a report.'
    });
  }

  getIncidentReports = (params) => {
    let url;
    this.setState({ loading: true });

    if (this.id()) {
      url = `/institutions/${this.id()}/incident-reports`;
    } else {
      url = '/incident-reports';
    }

    //Make call for incident-reports
    $.ajax({
      type: 'GET',
      url: url,
      data: params,
      dataType: 'json',
      contentType: 'application/json',
      success: (response) => { this.handleSuccess(response, params) },
      error: this.handleError,
      complete: this.handleComplete
    });
  }


  prepareExportDateRange = () => {
  
    //parses dates
    const startDate = new Date(this.state.filterParams.startDate);
    let endDate;
  
    // start date will always exist, but if endDate is not provided, set it to today's date
    if (!this.state.filterParams.endDate) {
      endDate = new Date();
      endDate.setHours(0, 0, 0, 0); // Set time to midnight
    } else {
      endDate = new Date(this.state.filterParams.endDate);
    }

    //create the date range in day numbers
    const differenceInDays = Math.floor((endDate - startDate) / (1000 * 3600 * 24));
   
   return differenceInDays; 
  }

  altFilterParams = () => {
    const filterParams = this.state.filterParams
    
    //check for other filter values 
    const nonEmptyKeys = Object.keys(filterParams).filter(key => {
      if (
        key !== "startDate" &&
        key !== "endDate" &&
        !(key === "archived" && filterParams[key] === false) &&
        (key === "status" ? filterParams[key].length > 0 : filterParams[key] !== "")
      ) {
        return true;
      }
      return false;
    });

    // If no value exists for any other key, return false
    return nonEmptyKeys.length > 0 ? nonEmptyKeys : false;
  };


  checkFilterBeforeExport = () => {
    const differenceInDays = this.prepareExportDateRange();
  
    // Logic to control export size
    if (differenceInDays <= 90 || (differenceInDays > 90 && this.altFilterParams())) {
      // If within acceptable range or within conditional range with additional filters, send export file
      this.sendExportFile();
    } else {
      // If unacceptable range or within conditional range without additional filters, surface error
      this.handleExportFilterError();
    }
  }

  sendExportFile = () => {
    let url;

    this.setState({exportDisabled: true});
      
    const params = {
      id: this.id(),
      filterParams: this.state.filterParams
    };

    if (this.id()) {
      url = `/institutions/${this.id()}/incident-reports/export`;
    } else {
      url = `/incident-reports/export`;
    }

    $.ajax({
      type: 'GET',
      url: url,
      data: params,
      dataType: 'json',
      contentType: 'application/json',
      success: (response) => { this.handleExportSuccess(response) },
      error: (err) => { this.handleExportError(err)}
    })
  }

  goToPage = (event) => {
    event.preventDefault();
    const id = event.currentTarget.textContent;
    let page 

    if (id == 'Previous') {
      page = this.state.page - 1;
    } else if (id == 'Next') {
      page = this.state.page + 1;
    }

    const params = {
      id: this.id(),
      filterParams: this.state.filterParams,
      page: page,
      limit: this.state.limit
    };

    this.setState({ page: page });
    this.getIncidentReports(params);
  }

  changePageLimit = (event) => {
    const limit = parseInt($(event.target).val());

    const params = {
      id: this.id(),
      filterParams: this.state.filterParams,
      page: 1,
      limit: limit
    };

    this.setState({ limit: limit });
    this.getIncidentReports(params);
  }

  id = () => {
    const url = window.location.href,
        index = url.indexOf('institution');

    if (index < 0) {
      return 0;
    } else {
      return parseInt(url.substring(index + 13));
    }
  }

  render() {
    const tableData = this.state.loading ? <TableLoader /> : (
      <IncidentReportTable
        id={this.id()}
        incidentReports={this.state.incidentReports}
        goToPage={this.goToPage}
        changePageLimit={this.changePageLimit}
        incidentReportsCount={this.state.incidentReportsCount}
        page={this.state.page}
        limit={this.state.limit}
        blankMsg={this.state.blankMsg}
        gifPath={this.state.gifPath} />
    );
  
    const filterDiv = (
      <IncidentReportFilter
        id={this.id()}
        incidentStatuses={this.state.incidentStatuses}
        updateFilterState={this.updateFilterState}
        updateStatusFilterState={this.updateStatusFilterState}
        updateSubtypeFilterState={this.updateSubtypeFilterState}
        updateInstitutionId={this.updateInstitutionId}
        proctorLocations={this.state.proctorLocations}
        serviceTypes={this.state.serviceTypes}
        submitFilterForm={this.submitFilterForm}
        filterParams={this.state.filterParams}
        dataRegionOptions={this.props.data_region_options}
        updateDataRegion={this.updateDataRegion}
        blankMsg={this.state.blankMsg}
        incidentReports={this.state.incidentReports}
        isDeptReadonly={this.props.department_name ? true : false}
      />
    );
  
    return (
      <div>
        <div className="row mb-4">
          <div className="col-md-12">
            <div className="float-right">
              <IncidentReportMoreOptionsButton
                id={this.id()}
                loading={this.state.loading}
                abilities={this.props.abilities_hash} />
              <IncidentReportExportButton
                handleExport={this.checkFilterBeforeExport}
                buttonDisabled={this.state.incidentReports.length <= 0}
              />
            </div>
            <h1 className="fs-3">{ window.polyglot.t('incident_report_center') }</h1>
          </div>
        </div>
        <div>
          <div className='row mb-4'>
            <div className='col-md-12'>
              <div className='card'>
                {filterDiv}
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='card'>
                {tableData}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IncidentReportApp
