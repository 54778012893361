import { useEffect, useState } from 'react';
// move these to a constants file
const MAX_RETRIES = 5;
const BASE_DELAY = 5000;
const MAX_DELAY = 30000;

const ShareScreenpageCard = ({ id, sendEvent, broadcastMessage }) => {
  const [retryCount, setRetryCount] = useState(0);
  const [prevDelay, setPrevDelay] = useState(BASE_DELAY);

  const getNextDelay = () => {
    const nextDelay = Math.min(
      MAX_DELAY,
      Math.random() * (prevDelay * 3 - BASE_DELAY) + BASE_DELAY,
    );
    setPrevDelay(nextDelay);
    return nextDelay;
  };

  useEffect(() => {
    let timeoutId;

    const requestScreenShare = async () => {
      if (retryCount < MAX_RETRIES) {
        try {
          await broadcastMessage({
            sender: 'archimedes',
            messageType: 'request_screen_share',
            data: {},
          });

          const retryDelay = getNextDelay();
          timeoutId = setTimeout(
            () => setRetryCount((prev) => prev + 1),
            retryDelay,
          );
        } catch (error) {
          console.error('Failed to request screen share:', error);
          setRetryCount((prev) => prev + 1);
        }
      } else {
        broadcastMessage({
          sender: 'archimedes',
          messageType: 'screen_share_failed',
          data: { error: 'Max retries reached' },
        });
        sendEvent('Event::ScreenShareFailed');
      }
    };

    if (retryCount === 0) {
      sendEvent('Event::ShareScreenpageLoaded');
    }
    requestScreenShare();

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [retryCount]);

  return (
    <div>
      <div id={id}></div>
    </div>
  );
};

export default ShareScreenpageCard;
