import React, { useMemo } from 'react';

import useCountdown from '../../../../hooks/useCountdown';
import { useMySessionsContext } from '../context';
import {
  formatDateTime,
  is24HourFormat,
} from '../../../Shared/DateTimeFormatter';

/** @typedef {import('../../../../types').GuardianPlatform} GuardianPlatform */
/** @typedef {import('../MySessions').Reservation} Reservation */

/**
 * @typedef LaunchExamButtonProps
 * @property {Reservation} reservation
 * @property {number} advanceCancellationReasonId
 * @property {boolean} isSupportedPlatform
 * @property {boolean} isHidden
 * @property {string} label
 */

/**
 * @param {LaunchExamButtonProps} props
 * @returns {React.ReactNode}
 */
const LaunchExamButton = ({
  reservation,
  advanceCancellationReasonId,
  isSupportedPlatform,
  isHidden,
  label,
}) => {
  if (isHidden || reservation.status === 'pending') {
    return <></>;
  }

  if (!isSupportedPlatform) {
    return (
      <div className="alert alert-primary" role="alert">
        {polyglot.t('my_sessions_v2_reservation_actions_unsupported_os_launch')}
      </div>
    );
  }

  return (
    <a
      className={`btn btn-success btn-lg start_button ${reservation.isStartDisabled ? 'disabled' : ''}`}
      href={reservation.startButtonDestination}
      data-reservation-id={reservation.id}
      data-exam-name={reservation.examName}
      data-custom-eligibility-enabled={reservation.isCustomEligibilityEnabled}
      data-cancellation-reason-id={advanceCancellationReasonId}
      data-placement="top"
      data-toggle="tooltip"
    >
      {label}
    </a>
  );
};

/**
 * @typedef RescheduleButtonProps
 * @property {Reservation} reservation
 * @property {boolean} isSupportedPlatform
 */

/**
 * @param {RescheduleButtonProps} props
 * @returns {React.ReactNode}
 */
export const RescheduleButton = ({ reservation, isSupportedPlatform }) => {
  if (
    !reservation.isReschedulable ||
    reservation.isReconnectable ||
    reservation.status === 'pending'
  ) {
    return <></>;
  }

  return (
    <div
      className={isSupportedPlatform ? 'd-inline' : 'text-right text-md-left'}
    >
      <a
        className="btn btn-link text-primary font-weight-bold p-0 pt-md-0 pt-2"
        href={reservation.reschedulePath}
        data-behavior="reschedule"
        aria-label={`Reschedule for exam ${reservation.examName}`}
      >
        {polyglot.t('my_sessions_v2_reservation_actions_reschedule')}
      </a>
    </div>
  );
};

/**
 * @typedef ViewInCartProps
 * @property {string} studentOrderPath
 * @property {boolean} isSupportedPlatform
 */

/**
 * @param {ViewInCartProps} props
 * @returns {React.ReactNode}
 */
export const ViewInCart = ({ studentOrderPath, isSupportedPlatform }) => {
  return (
    <div
      className={isSupportedPlatform ? 'd-inline' : 'text-right text-md-left'}
    >
      <a
        className="btn btn-link text-primary font-weight-bold p-0 pt-md-0 pt-2"
        href={studentOrderPath}
      >
        {polyglot.t('my_sessions_v2_reservation_actions_view_in_cart')}
      </a>
    </div>
  );
};

/**
 * @typedef CancelButtonProps
 * @property {Reservation} reservation
 * @property {string} studentReservationUrl
 * @property {string} studentOrderPath
 * @property {number[]} cancellationRequiredIds
 * @property {boolean} isSupportedPlatform
 * @property {string} startsAtLocalized
 */

/**
 * @param {CancelButtonProps} props
 * @returns {React.ReactNode}
 */
export const CancelButton = ({
  reservation,
  studentReservationUrl,
  studentOrderPath,
  cancellationRequiredIds,
  isSupportedPlatform,
  startsAtLocalized,
}) => {
  const showCancelModal =
    !reservation.fulfillment?.ptc ||
    !reservation.fulfillment?.isSurchargesExist;

  if (!reservation.fulfillment?.isCancellable) {
    return <></>;
  }

  return (
    <div
      className={isSupportedPlatform ? 'd-inline' : 'text-right text-md-left'}
    >
      {showCancelModal ? (
        <a
          className={`btn btn-link text-primary font-weight-bold p-0 pt-md-0 pt-2 ${isSupportedPlatform ? '' : 'text-right text-md-left'}`}
          href="#"
          data-toggle="modal"
          data-target="#cancel-modal"
          data-reservation-uuid={reservation.uuid}
          data-fulfillment-uuid={reservation.fulfillment.uuid}
          data-form-url={reservation.cancellationPath}
          data-stop-session={reservation.fulfillment.isRunning}
          data-hide-parent={false}
          data-comments-required-ids={cancellationRequiredIds}
          data-submit-confirm={null}
          data-success-url={studentReservationUrl}
          data-first-name={reservation.fulfillment.userFirstName}
          data-exam-name={reservation.fulfillment.examName}
          data-starts-at={startsAtLocalized}
        >
          {polyglot.t('my_sessions_v2_reservation_actions_cancel')}
        </a>
      ) : (
        <a
          className={`btn btn-link font-weight-bold p-0 pt-md-0 pt-2 ${isSupportedPlatform ? '' : 'text-right text-md-left'}`}
          href={studentOrderPath}
        >
          {polyglot.t('my_sessions_v2_reservation_actions_cancel')}
        </a>
      )}
    </div>
  );
};

/**
 * @typedef ReservationActionsProps
 * @property {Reservation} reservation
 * @property {string} studentReservationUrl
 * @property {string} studentOrderPath
 * @property {number[]} cancellationRequiredIds
 * @property {number} advanceCancellationReasonId
 * @property {boolean} isSupportedPlatform
 * @property {boolean} isDevelopmentEnv
 * @property {string} timezone
 */

/**
 * @param {ReservationActionsProps} props
 * @returns {React.ReactNode}
 */
export const ReservationActions = ({
  reservation,
  studentReservationUrl,
  studentOrderPath,
  cancellationRequiredIds,
  advanceCancellationReasonId,
  isSupportedPlatform,
  isDevelopmentEnv,
  timezone,
}) => {
  const { setReservationAccessible } = useMySessionsContext();

  const accessibleTimeString = reservation.fulfillment?.adjustedDate;
  const is24HrFormat = is24HourFormat();
  const format = is24HrFormat ? 'ddd MM/DD/YY HH:mm z' : 'ddd MM/DD/YY h:mmA z';
  const startsAtLocalized = formatDateTime(
    reservation.fulfillment?.startsAt,
    format,
    timezone,
  );
  const accessibleTime = useMemo(
    () => Date.parse(accessibleTimeString),
    [accessibleTimeString],
  );

  useCountdown(accessibleTime, {
    onComplete: () => setReservationAccessible(reservation.uuid),
  });

  const isHidden =
    !isDevelopmentEnv ||
    (reservation.fulfillment?.isAccessible ?? false) ||
    reservation.fulfillment.ptc;

  const getClassNames = (isHidden, isSupportedPlatform) => {
    if (isHidden) {
      return `${isSupportedPlatform && (reservation.fulfillment?.isAccessible || reservation.isOnOpenOrder || (!reservation.isOnOpenOrder && !reservation.isFulfillmentPresent)) ? 'd-sm-flex' : reservation.fulfillment?.isAccessible ? 'd-block' : 'd-none d-md-flex'}`;
    }
    return isSupportedPlatform ? 'd-sm-flex' : 'd-block';
  };

  return (
    <div
      className={`${getClassNames(isHidden, isSupportedPlatform)} mt-4 mt-sm-4 mt-md-2 justify-content-between justify-content-md-start align-items-center gap-medium`}
    >
      <LaunchExamButton
        reservation={reservation}
        advanceCancellationReasonId={advanceCancellationReasonId}
        isSupportedPlatform={isSupportedPlatform}
        isHidden={
          !isDevelopmentEnv || (reservation.fulfillment?.isAccessible ?? false)
        }
        label={polyglot.t('my_sessions_v2_reservation_actions_launch_exam')}
      />
      {reservation.fulfillment?.uuid ? (
        <>
          <LaunchExamButton
            reservation={reservation}
            advanceCancellationReasonId={advanceCancellationReasonId}
            isSupportedPlatform={isSupportedPlatform}
            isHidden={
              !reservation.fulfillment.isAccessible ||
              reservation.fulfillment.ptc
            }
            label={
              reservation.isReconnectable
                ? polyglot.t('my_sessions_v2_reservation_actions_reconnect')
                : polyglot.t('my_sessions_v2_reservation_actions_launch_exam')
            }
          />
          {reservation.fulfillment?.isAccessible &&
          !reservation.isOnOpenOrder ? (
            <RescheduleButton
              className="d-block"
              reservation={reservation}
              isSupportedPlatform={isSupportedPlatform}
            />
          ) : reservation.isOnOpenOrder ? (
            <ViewInCart
              className="d-block"
              studentOrderPath={studentOrderPath}
              isSupportedPlatform={isSupportedPlatform}
            />
          ) : (
            <div
              className={`d-flex gap-medium float-right float-lg-left ${isHidden ? 'd-none d-md-inline-flex' : ''}`}
            >
              <RescheduleButton
                reservation={reservation}
                isSupportedPlatform={isSupportedPlatform}
              />
              <CancelButton
                reservation={reservation}
                studentReservationUrl={studentReservationUrl}
                studentOrderPath={studentOrderPath}
                cancellationRequiredIds={cancellationRequiredIds}
                isSupportedPlatform={isSupportedPlatform}
                startsAtLocalized={startsAtLocalized}
              />
            </div>
          )}
        </>
      ) : reservation.isOnOpenOrder ? (
        <ViewInCart
          className="d-block"
          studentOrderPath={studentOrderPath}
          isSupportedPlatform={isSupportedPlatform}
        />
      ) : (
        <a
          className="font-weight-bold d-block"
          href={reservation.editStudentReservationPath}
        >
          {polyglot.t('my_sessions_v2_reservation_actions_schedule')}
        </a>
      )}
    </div>
  );
};
