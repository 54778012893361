import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

/** @typedef {import('./MySessions').Reservation} Reservation */
/** @typedef {import('./MySessions').MySessionsAssets} MySessionsAssets */

/**
 * @typedef MySessionsContextData
 * @property {Reservation[]} reservations
 * @property {React.Dispatch<React.SetStateAction<Reservation[]>>} setReservations
 * @property {Reservation[]} scheduledReservations
 * @property {Reservation[]} unscheduledReservations
 * @property {(reservationUuid: string) => void} setReservationAccessible
 * @property {(reservationUuid: string) => void} setReservationCancelled
 * @property {MySessionsAssets} mySessionsAssets
 */

/**
 * Custom context for the My Sessions feature.
 * @type {React.Context<MySessionsContextData>}
 */
export const MySessionsContext = createContext(undefined);

/**
 * Custom hook to use the {@link MySessionsContext}.
 * @returns {MySessionsContextData}
 */
export function useMySessionsContext() {
  return useContext(MySessionsContext);
}

/**
 * @typedef MySessionsContextProviderProps
 * @property {Reservation[]} initialReservations
 * @property {MySessionsAssets} mySessionsAssets
 */

/**
 * Component used to provide the {@link MySessionsContext} to child components.
 * @param {React.PropsWithChildren<MySessionsContextProviderProps>} props
 * @returns {React.ReactNode}
 */
export const MySessionsContextProvider = ({
  initialReservations,
  mySessionsAssets,
  children,
}) => {
  const [reservations, setReservations] = useState(initialReservations);

  /**
   * Determines whether the given reservation can be considered 'scheduled'
   * (i.e., whether it can be shown in the {@link ScheduledExams} component).
   * @param {Reservation} reservation
   * @returns {boolean}
   */
  const isScheduledReservation = useCallback(
    /** @param {Reservation} reservation */
    (reservation) => ['scheduled', 'running'].includes(reservation.status),
    [],
  );

  /**
   * Determines whether the given reservation can be considered 'unscheduled'
   * (i.e., whether it can be shown in the {@link UnscheduledExams} component).
   * @param {Reservation} reservation
   * @returns {boolean}
   */
  const isUnscheduledReservation = useCallback(
    /** @param {Reservation} reservation */
    (reservation) => ['pending'].includes(reservation.status),
    [],
  );

  const scheduledReservations = useMemo(() => {
    return reservations.filter(isScheduledReservation);
  }, [reservations]);

  const unscheduledReservations = useMemo(() => {
    return reservations.filter(isUnscheduledReservation);
  }, [reservations]);

  /**
   * Updates the `reservations` state variable such that the reservation with
   * the given uuid is modified to reflect that it is now accessible.
   * @param {string} reservationUuid
   * @returns {void}
   */
  const setReservationAccessible = useCallback(
    /** @param {string} reservationUuid */
    (reservationUuid) => {
      setReservations((prevReservations) =>
        prevReservations.map((reservation) =>
          reservation.uuid === reservationUuid
            ? /** @type {Reservation} */
              {
                ...reservation,
                fulfillment: {
                  ...reservation.fulfillment,
                  isAccessible: true,
                },
              }
            : reservation,
        ),
      );
    },
    [],
  );

  /**
   * Updates the `reservations` state variable such that the reservation with
   * the given uuid is modified to reflect that it is now cancelled.
   * @param {string} reservationUuid
   * @returns {void}
   */
  const setReservationCancelled = useCallback(
    /** @param {string} reservationUuid */
    (reservationUuid) => {
      setReservations((prevReservations) =>
        prevReservations.map((reservation) =>
          reservation.uuid === reservationUuid
            ? /** @type {Reservation} */
              {
                ...reservation,
                status: 'cancelled',
              }
            : reservation,
        ),
      );
    },
    [],
  );

  useEffect(() => {
    window.setReservationCancelled = function (reservationUuid) {
      setReservationCancelled(reservationUuid);
    };

    return () => delete window.setReservationCancelled;
  }, [setReservationCancelled]);

  return (
    <MySessionsContext.Provider
      value={{
        reservations,
        setReservations,
        scheduledReservations,
        unscheduledReservations,
        setReservationAccessible,
        setReservationCancelled,
        mySessionsAssets,
      }}
    >
      {children}
    </MySessionsContext.Provider>
  );
};
