import React from 'react';

import { ActionLinks } from './Components/ActionLinks';
import { ExamHistory } from './Components/ExamHistory';
import { ExamPageHeader } from './Components/ExamPageHeader';
import { ScheduledExams } from './Components/ScheduledExams';
import { UnscheduledExams } from './Components/UnscheduledExams';
import { MySessionsContextProvider } from './context';

/**
 * MySessions component to display the user's sessions.
 * @param {MySessionsProps} props
 * @returns {React.ReactNode}
 */
export default function MySessions(props) {
  return (
    <MySessionsContextProvider
      initialReservations={props.reservations}
      mySessionsAssets={props.mySessionsAssets}
    >
      <div className="my-sessions-v2">
        <ExamPageHeader selectStudentExamsPath={props.selectStudentExamsPath} />
        <ActionLinks {...props} />
        <ScheduledExams {...props} />
        <UnscheduledExams {...props} />
        <ExamHistory
          sessionHistory={props.sessionHistory}
          timezone={props.timezone}
        />
      </div>
    </MySessionsContextProvider>
  );
}
