import CancelledAppointment from '../../components/Cancellations/CancelledAppointment';
import Toast from '../utils/Toast';
import csrfToken from '../utils/csrf';
import urlEncoder from '../utils/urlEncoder';

/**
 * @typedef {DOMStringMap} CancellationModalParams
 * @property {string} toggle
 * @property {string} target
 * @property {string} reservationUuid
 * @property {string} fulfillmentUuid
 * @property {string} formUrl
 * @property {string} stopSession
 * @property {string} [hideParent] - TODO: No longer used?
 * @property {string} commentsRequiredIds
 * @property {string} [submitConfirm]
 * @property {string} [successUrl]
 * @property {string} firstName
 * @property {string} examName
 * @property {string} startsAt
 */

/**
 * @typedef CancellationResponse
 * @property {boolean} cancellation_fee_in_cart
 * @property {{ explaination: string[], external_cancellation: string[] }} errors
 */

class CancellationModal {
  constructor() {
    // Bootstrap runs on jQuery, so we need jQuery here.

    this.cancelModalBtn = document.querySelector(
      '[data-behavior="cancel-modal-button"]',
    );
    this.submitBtn = document.querySelector('.js-btn-cancel');

    this.handleReasonChange = this.handleReasonChange.bind(this);
    this.submitCancellation = this.submitCancellation.bind(this);
    this.createCancellationMessage = this.createCancellationMessage.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.redirectToCart = this.redirectToCart.bind(this);
  }

  init() {
    this.bindEventListeners();
  }

  bindEventListeners() {
    $('#cancel-modal').on(
      'show.bs.modal',
      /** @param {Event & { relatedTarget?: HTMLElement }} event */
      (event) => {
        this.cancelResBtn =
          /** @type {HTMLButtonElement} */ event.relatedTarget;
        this.cancelResBtnData =
          /** @type {CancellationModalParams} */ this.cancelResBtn.dataset;

        this.fulfillmentUuid = this.cancelResBtnData.fulfillmentUuid;
        this.formUrl = this.cancelResBtnData.formUrl;
        this.stopSession = this.cancelResBtnData.stopSession === 'true';
        this.confirmMessage = this.cancelResBtnData.submitConfirm;

        this.isAdmin = !!document.querySelector('.cancel-first-name');
        this.isWaived =
          /** @type {HTMLInputElement} */
          document.querySelector('input[name=cancellation_fee]');

        this.forceReasonChange();
        this.populateModal();
      },
    );
    document
      .querySelector('[data-behavior="cancel-modal-button"]')
      .addEventListener('click', this.handleCancelClick);
    this.submitBtn.addEventListener('click', this.submitCancellation);
    document
      .querySelector('select#reason')
      .addEventListener('change', this.handleReasonChange);
  }

  /**
   * Handles when the user selects an option in the reason menu.
   *
   * If the selected reason requires further explanation from the user, then the
   * 'Comment' field will be marked as required. Otherwise, the 'Comment' field
   * will be marked as *not* required.
   *
   * @returns {void}
   */
  handleReasonChange() {
    const explanationLabel = document.querySelector(
      '[for="explanation"].col-form-label',
    );
    if (this.commentsRequired()) {
      explanationLabel.classList.add('required');
    } else {
      explanationLabel.classList.remove('required');
    }
  }

  /**
   * Handles when the cancel button is clicked.
   * @param {MouseEvent} event
   * @returns {void}
   */
  handleCancelClick(event) {
    event.preventDefault();
    this.closeModal();
    this.redirectToCart();
  }

  closeModal() {
    document.querySelector('#cancel-modal textarea').value = '';
    $('#cancel-modal').modal('hide');
    this.resetSubmitBtn();
  }

  resetSubmitBtn() {
    this.submitBtn.classList.remove('disabled');
    this.submitBtn.value = 'Submit';
    this.cancelModalBtn.classList.remove('disabled');
  }

  /**
   * Handles when the submit button is clicked.
   * @param {MouseEvent} event
   * @returns {void}
   */
  submitCancellation(event) {
    event.preventDefault();

    // If the exam is not a PTC exam, the `isWaived` checkbox will be null.
    if (this.isAdmin && !!this.isWaived && !this.isWaived.checked) {
      alert(
        "You cannot cancel this exam without agreeing to waive the test taker's cancellation fee.",
      );
      this.resetSubmitBtn();
      return;
    }

    // If operating through Chrome extension...
    if (this.fulfillmentUuid && this.stopSession) {
      this.createCancellationMessage();
    }

    this.submitBtn.classList.add('disabled');
    this.submitBtn.value = 'Submitting...';
    this.cancelModalBtn.classList.add('disabled');

    // Prompt the user to confirm whether they want to proceed to send the cancellation request (if applicable)
    if (this.confirmMessage && !confirm(this.confirmMessage)) {
      // The user has cancelled the cancellation dialog; abort the cancellation.
      return;
    }

    // The user has confirmed the cancellation dialog; send the cancellation request.
    this.createCancellation()
      .then((response) => response.json())
      .then(
        /** @param {CancellationResponse} response */
        (response) => {
          /** @type {HTMLSpanElement} */
          const errorElement = document.querySelector(
            '[data-element="cancellation-explanation-error"]',
          );

          this.cancellation_fee_in_cart = response.cancellation_fee_in_cart;

          if (response.errors) {
            if (response.errors.explaination) {
              // The error is from the explanation; display the error element.
              errorElement.innerText = response.errors.explaination.join(', ');
              errorElement.classList.remove('display-none');
            } else {
              // The error is from the external cancellation; display a toast.
              const message = response.errors.external_cancellation.join(', ');
              new Toast().danger({ message });
            }
            this.resetSubmitBtn();
          } else {
            this.updatePageAfterCancellation(errorElement);
          }
        },
      );
  }

  /**
   * Updates the page after submitting the cancellation request.
   *
   * If the flight path card is shown (i.e., on the flight path page), then the
   * flight path will be cancelled. Otherwise, the table row element containing
   * the 'Cancel' button that was clicked will be removed. Additionally, if the
   * countdown card is shown (i.e., on the reservations page), then it will be
   * removed. Ultimately, this modal will be closed, and the user will be
   * redirected to the cart if this action should result in a cancellation fee.
   *
   * @param {HTMLSpanElement} errorElement
   * @returns {void}
   */
  updatePageAfterCancellation(errorElement) {
    const isMySessionsV2 = !!document.querySelector('.my-sessions-v2');
    if (isMySessionsV2) {
      const { reservationUuid } = this.cancelResBtnData;
      window.setReservationCancelled?.(reservationUuid);

      errorElement.classList.add('display-none');
      errorElement.innerText = '';

      this.closeModal();
      this.redirectToCart();

      return;
    }

    const isFlightPathCard = !!document.querySelector('.js-flightpath-card');
    if (isFlightPathCard) {
      this.cancelFlightPath();
      this.adjustReservationPanel();
    } else {
      // If on any other page, remove table row corresponding to cancelled exam.
      const examReservationTableRow = this.cancelResBtn.closest('tr');
      examReservationTableRow.remove();
    }

    // Remove the countdown panel, as we cannot promise that the exam they
    // cancelled is the closest one or not, so the countdown could be wrong.
    const countdownPanel = document.querySelector('.js-row-panel-countdown');
    countdownPanel?.remove();

    errorElement.classList.add('display-none');
    errorElement.innerText = '';

    this.closeModal();
    this.redirectToCart();
  }

  /**
   * Disables the flight path component and removes buttons.
   * @returns {void}
   */
  cancelFlightPath() {
    const newNode = document.createElement('div');
    newNode.id = 'alert-container';
    document.querySelector('.card').after(newNode);
    if (!document.querySelector('.js-show-disabled-flight-path')) {
      ReactDOM.render(
        <CancelledAppointment />,
        document.querySelector('#alert-container'),
      );
      document
        .querySelector('.js-flightpath-card')
        .classList.add('not-authorized');
      document.querySelector('.btn-danger').remove();
      document.querySelector('.btn-success').remove();
    }
  }

  adjustReservationPanel() {
    const sessionStatusDetails = document.querySelector(
      '[data-behavior="session-status-dd"]',
    );
    const appointmentStatusDetails = document.querySelector(
      '[data-behavior="appointment-status-dd"]',
    );
    sessionStatusDetails.innerText = 'Cancelled';
    appointmentStatusDetails.innerText = 'Cancelled';
  }

  /**
   * Creates a cancellation request and sends it to the backend.
   * @returns {Promise<Response>} A promise that resolves with the response to
   *   the cancellation request or rejects with an error if the request fails.
   */
  createCancellation() {
    const reasonSelect =
      /** @type {HTMLSelectElement} */
      document.querySelector('select#reason');
    const xVoucherInput =
      /** @type {HTMLInputElement} */
      document.querySelector('input[name=xvoucher]:checked');
    const creditInput =
      /** @type {HTMLInputElement} */
      document.querySelector('input[name=credit]:checked');

    // If any of these params are `null`, our system tests will break!
    const cancellationParams = {
      cancellation_reason_id: reasonSelect.value,
      explaination: this.populateExplanation(),
      xvoucher_excused: xVoucherInput?.value === 'excused',
      credit: creditInput.value,
      is_waived: this.isWaived?.checked ?? false,
    };

    return fetch(this.formUrl, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrfToken(),
      },
      body: urlEncoder.encode({
        cancellation: cancellationParams,
      }),
    });
  }

  /**
   * Handles Chrome extension cancellation messages.
   * @returns {void}
   */
  createCancellationMessage() {
    void fetch('/api/cancellation_messages', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrfToken(),
      },
      body: urlEncoder.encode({
        uuid: this.fulfillmentUuid,
      }),
    });
  }

  forceReasonChange() {
    const event = new Event('change');
    document.querySelector('select#reason').dispatchEvent(event);
  }

  populateModal() {
    if (this.isWaived != null) {
      this.isWaived.checked = false;
    }
    if (this.isAdmin) {
      document.querySelector('.cancel-first-name').innerHTML =
        this.cancelResBtnData.firstName;
    }
    document.querySelector('.cancel-exam-name').innerHTML =
      this.cancelResBtnData.examName;
    document.querySelector('.cancel-starts-at').innerHTML =
      this.cancelResBtnData.startsAt;
  }

  /**
   * Generates a generic explanation for this cancellation, depending on the
   * user's role, unless the user has already entered an explanation.
   * @returns {string}
   */
  populateExplanation() {
    const explanationTextArea = document.querySelector('textarea#explanation');
    const currentExplanation = explanationTextArea.value;
    if (currentExplanation) {
      return currentExplanation;
    }
    return `Session cancelled by ${this.isAdmin ? 'Proctor' : 'Test-Taker'}`;
  }

  /**
   * Checks whether the currently selected reason requires an explanation.
   * @returns {boolean}
   */
  commentsRequired() {
    const reasonsRequiringExplanationsIds =
      this.cancelResBtnData.commentsRequiredIds.slice(1, -1).split(',');

    /** @type {HTMLSelectElement} */
    const reasonSelect = document.querySelector('select#reason');
    this.selectedReason = reasonSelect.options[reasonSelect.selectedIndex];
    const selectedReasonId = this.selectedReason.value;

    return reasonsRequiringExplanationsIds.includes(selectedReasonId);
  }

  redirectToCart() {
    if (!this.cancellation_fee_in_cart) return;

    const { protocol, host } = window.location;
    window.location.href = `${protocol}//${host}/students/order`;
  }
}

export default CancellationModal;
