import React from 'react';
import { NavbarV2 } from './NavbarV2';
import { StudentNavigation } from './StudentNavigation';

/**
 * StudentNavWrapper component wraps the primary and secondary navbar for student layout.
 *
 * @param {StudentNavWrapperProps} props
 * @returns {React.ReactNode}
 */
export default function StudentNavWrapper({ navbar, studentNavigation }) {
  return (
    <>
      <NavbarV2
        navbar={navbar}
        menuItems={[
          {
            title: polyglot.t('navbar_student_navigation_my_sessions'),
            link: studentNavigation.mySessionsNavLink,
          },
          {
            title: polyglot.t('navbar_student_navigation_my_orders'),
            link: studentNavigation.myOrderNavLink,
          },
          {
            title: polyglot.t('navbar_student_navigation_support'),
            link: studentNavigation.supportNavLink,
          },
          studentNavigation.hasBalance && {
            title: polyglot.t('navbar_student_navigation_balance'),
            count: studentNavigation.accountBalance,
          },
          {
            title: polyglot.t('navbar_student_navigation_my_cart'),
            link: studentNavigation.studentOrderPath,
            count: studentNavigation.cartQuantitySize,
          },
        ]}
      />
      <StudentNavigation studentNavigation={studentNavigation} />
    </>
  );
}
