import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { useTranslate } from 'react-polyglot';

import Lounging from '../../../assets/images/second_camera/lounging.svg';

const ExamComplete = (props) => {
  const t = useTranslate();
  return (
    <div className="d-flex flex-column justify-content-center align-items-center vh-100 mb-5">
      <Row>
        <Col className="d-flex justify-content-center">
          <Image
            className="vw-15 "
            src={Lounging}
            alt={t('exam_complete.image_alt_text')}
          />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center align-items-center mb-2">
          <h6>
            {' '}
            <i
              className="fa fa-check-circle exam-complete-icon"
              aria-label="Check icon"
            />{' '}
            {t('exam_complete.page_heading')}
          </h6>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center align-items-center mb-4">
          <p>{t('exam_complete.nicework_session_ended')}</p>
        </Col>
      </Row>
    </div>
  );
};

export default ExamComplete;
