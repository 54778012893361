import React from 'react';
import moment from 'moment';

import { ExamRulesModal } from '../Modals/ExamRulesModal';
import {
  CancelButton,
  RescheduleButton,
  ViewInCart,
} from './ReservationActions';
import {
  formatDateTime,
  is24HourFormat,
} from '../../../Shared/DateTimeFormatter';

/** @typedef {import('../MySessions').Reservation} Reservation */
/** @typedef {import('../MySessions').SessionHistoryItem} SessionHistoryItem */

/**
 * @typedef NormalReservationListItemProps
 * @property {Reservation} reservation
 * @property {string} studentReservationUrl
 * @property {string} studentOrderPath
 * @property {number[]} cancellationRequiredIds
 * @property {boolean} isSupportedPlatform
 * @property {boolean} isDevelopmentEnv
 * @property {string} timezone
 */

/**
 * @typedef SessionHistoryItemProps
 * @property {SessionHistoryItem} reservation
 * @property {'history' | undefined} category
 */

/** @typedef {React.PropsWithChildren<NormalReservationListItemProps> | SessionHistoryItemProps} ReservationListItemProps */

/**
 * @todo Consider splitting this component into two components so that the use
 *   case in which `reservation` is of type `SessionHistoryItem` can be handled
 *   separately. This would greatly reduce the complexity of this component, as
 *   it's currently quite convoluted for what it does.
 * @param {ReservationListItemProps} props
 * @returns {React.ReactNode}
 */
export function ReservationListItem({
  reservation,
  studentReservationUrl,
  studentOrderPath,
  cancellationRequiredIds,
  isSupportedPlatform,
  isDevelopmentEnv,
  category,
  children,
  timezone,
}) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const isHistory = category === 'history';

  const getFormattedExamDateTime = (isDate = true) => {
    const defaultTime = reservation.fulfillment?.startsAt;
    const historyTime = reservation.isFulfilled
      ? reservation.formattedExamTime
      : reservation.fulfillmentWindowClosingTime;
    const result = isHistory ? historyTime : defaultTime;
    const is24HrFormat = is24HourFormat();
    const timeFormat = is24HrFormat ? 'HH:mm z' : 'h:mmA z';
    const dateFormat = 'MM/DD/YY';
    const format = isDate ? dateFormat : timeFormat;

    return formatDateTime(result, format, timezone).trim();
  };
  const formattedExamDate = getFormattedExamDateTime(true);
  const formattedExamTime = getFormattedExamDateTime(false);

  const getHistoryStatus = () => {
    if (reservation.isFulfilled) {
      return polyglot.t('my_sessions_v2_reservation_list_session_fulfilled');
    } else {
      return polyglot.t('my_sessions_v2_reservation_list_exam_ended');
    }
  };

  const handleToggleExamRulesModal = () => {
    setIsModalOpen((previousState) => !previousState);
  };

  const renderScheduleButton = () => (
    <a
      className="font-weight-bold d-inline-flex d-md-none gap-medium"
      href={reservation.editStudentReservationPath}
    >
      {polyglot.t('my_sessions_v2_reservation_actions_schedule')}
    </a>
  );

  const renderRescheduleAndCancelButtons = () => (
    <div className="d-md-none d-inline-flex gap-medium text-md-left">
      <RescheduleButton
        reservation={reservation}
        isSupportedPlatform={isSupportedPlatform}
      />
      <CancelButton
        reservation={reservation}
        studentReservationUrl={studentReservationUrl}
        studentOrderPath={studentOrderPath}
        cancellationRequiredIds={cancellationRequiredIds}
        isSupportedPlatform={isSupportedPlatform}
      />
    </div>
  );

  const renderViewInCart = () => (
    <ViewInCart
      className="d-inline-flex d-md-none gap-medium"
      studentOrderPath={studentOrderPath}
      isSupportedPlatform={isSupportedPlatform}
    />
  );

  const renderReservationActions = () => {
    const hasFulfillmentUuid = !!reservation.fulfillment?.uuid;
    const isAccessible = reservation.fulfillment?.isAccessible;
    const isOnOpenOrder = reservation.isOnOpenOrder;
    const isHidden =
      !isDevelopmentEnv ||
      (reservation.fulfillment?.isAccessible ?? false) ||
      reservation.fulfillment?.ptc;

    if (hasFulfillmentUuid) {
      if (!isAccessible && !isOnOpenOrder && isHidden) {
        return renderRescheduleAndCancelButtons();
      }

      if (isOnOpenOrder && isHidden && !isSupportedPlatform) {
        return renderViewInCart();
      }

      return <></>;
    }

    if (isOnOpenOrder && !isSupportedPlatform) {
      return renderViewInCart();
    }

    if (!isSupportedPlatform) {
      return renderScheduleButton();
    }

    return <></>;
  };

  const isOnlineReservation =
    !reservation.fulfillment?.ptc ||
    !reservation.fulfillment?.ptcLocationAddress1;

  return (
    <div
      className="reservation-list-item"
      data-reservation-uuid={reservation.uuid}
    >
      <div className="row">
        <div className="col-md-6 align-self-center">
          <h5 className="card-title exam-name fs-5 font-weight-bold">
            {reservation.examName}
          </h5>
          <p className="card-text text-muted fs-6 institution-name">
            {reservation.institutionName}
          </p>
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-4 align-self-center exam-datetime-container">
              <p
                className={`card-text mb-0 fs-6 ${formattedExamDate === '' && 'd-none'}`}
              >
                {formattedExamDate}
              </p>
              <p
                className={`card-text fs-6 ${formattedExamTime === '' && 'd-none'}`}
              >
                {formattedExamTime}
              </p>
            </div>
            <div
              className={`col-md-4 text-md-left align-self-center ${
                isHistory && !reservation.decoratedReservationStatus
                  ? ''
                  : 'badge-container'
              }`}
            >
              {!isHistory && (
                <>
                  {isOnlineReservation && (
                    <span
                      data-testid="online-badge"
                      className="badge badge-dark mr-1"
                    >
                      {polyglot.t('my_sessions_v2_reservation_list_online')}
                    </span>
                  )}
                  <span className="badge badge-dark">
                    {reservation.iteration.serviceLineDecorated}
                  </span>
                </>
              )}
              {isHistory && (
                <div className="fs-6">
                  {reservation.decoratedReservationStatus}
                </div>
              )}
            </div>
            <div
              className={`col-md-4 text-md-left align-self-center d-flex justify-content-between align-items-center d-md-block`}
            >
              {!isHistory && reservation.shouldShowExamRulesButton && (
                <button
                  className="btn btn-primary btn-sm"
                  onClick={handleToggleExamRulesModal}
                >
                  {polyglot.t('my_sessions_v2_reservation_list_exam_rules')}
                </button>
              )}
              <div
                className={`${reservation.isOnOpenOrder ? 'd-md-none' : 'd-md-block'}`}
              >
                {!isHistory && renderReservationActions()}
              </div>

              {isHistory && (
                <p className="card-text mb-0 fs-6">{getHistoryStatus()}</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {!isHistory && (
        <div className="row mt-2">
          <div className="col-lg-12 align-self-center">{children}</div>
        </div>
      )}

      {reservation.shouldShowExamRulesButton && (
        <ExamRulesModal
          reservation={reservation}
          show={isModalOpen}
          handleCloseModal={handleToggleExamRulesModal}
        />
      )}
    </div>
  );
}
