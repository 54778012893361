import DOMPurify from 'dompurify';
import React from 'react';
import { Button, Col, Image, Modal, Row } from 'react-bootstrap';

import { removeHtmlTags } from '../../../../utils/RemoveHtmlTags';
import { useMySessionsContext } from '../context';

/** @typedef {import('../MySessions').Reservation} Reservation */

/**
 * @returns {React.ReactNode}
 */
const Guidelines = () => {
  const {
    mySessionsAssets: { stayInFrame, staySeated, stayQuiet, stayPrivate },
  } = useMySessionsContext();

  return (
    <Row className="m-0">
      <Col xs={3} className="p-0">
        <Image
          src={stayInFrame}
          className="mx-auto guideline-image"
          aria-describedby="stay_in_frame"
        />
        <p className="text-center mb-0" id="stay_in_frame" tabIndex={0}>
          {polyglot.t('my_sessions_v2_exam_rules_stay_in_frame')}
        </p>
      </Col>
      <Col xs={3} className="p-0">
        <Image
          src={staySeated}
          className="mx-auto guideline-image"
          aria-describedby="stay_seated"
        />
        <p className="text-center mb-0" id="stay_seated" tabIndex={0}>
          {polyglot.t('my_sessions_v2_exam_rules_stay_seated')}
        </p>
      </Col>
      <Col xs={3} className="p-0">
        <Image
          src={stayQuiet}
          className="mx-auto guideline-image"
          aria-describedby="stay_quiet"
        />
        <p className="text-center mb-0" id="stay_quiet" tabIndex={0}>
          {polyglot.t('my_sessions_v2_exam_rules_stay_quiet')}
        </p>
      </Col>
      <Col xs={3} className="p-0">
        <Image
          src={stayPrivate}
          className="mx-auto guideline-image"
          aria-describedby="stay_private"
        />
        <p className="text-center mb-0" id="stay_private" tabIndex={0}>
          {polyglot.t('my_sessions_v2_exam_rules_stay_private')}
        </p>
      </Col>
    </Row>
  );
};

/**
 * @typedef RulesProps
 * @property {Reservation} reservation
 */

/**
 * @param {RulesProps} props
 * @returns {React.ReactNode}
 */
const Rules = ({ reservation }) => {
  return (
    <>
      <div className="section-title mb-2" tabIndex={0}>
        {polyglot.t('my_sessions_v2_exam_rules_review_rules')}
      </div>
      {reservation.examRulesDisplaySecuritySettings ? (
        <ul>
          {reservation.iteration?.browserTabs === 'restricted' && (
            <li tabIndex={0}>
              {polyglot.t('my_sessions_v2_exam_rules_close_all_browser_tabs')}
            </li>
          )}
          {reservation.iteration?.allowCopyPaste != null &&
            reservation.iteration?.allowCopyPaste !== 'enabled' && (
              <li tabIndex={0}>
                {polyglot.t('my_sessions_v2_exam_rules_no_copy_text_image')}
              </li>
            )}
          {reservation.iteration?.windowSizeAllowed === 'maximized' && (
            <li tabIndex={0}>
              {polyglot.t('my_sessions_v2_exam_rules_window_maximized')}
            </li>
          )}
          {reservation.iteration?.windowSizeAllowed === 'fullscreen' && (
            <li tabIndex={0}>
              {polyglot.t('my_sessions_v2_exam_rules_window_fullscreen')}
            </li>
          )}
          {reservation.iteration?.lostFocus === 'restricted' && (
            <li tabIndex={0}>
              {polyglot.t('my_sessions_v2_exam_rules_no_switch_app')}
            </li>
          )}
        </ul>
      ) : (
        <p className="section-content" tabIndex={0}>
          {polyglot.t('my_sessions_v2_exam_rules_review_other_guidelines')}
        </p>
      )}
    </>
  );
};

/**
 * @returns {React.ReactElement}
 */
const SecondCamera = () => {
  return (
    <>
      <div className="section-title mb-2" tabIndex={0}>
        {polyglot.t('my_sessions_v2_exam_rules_second_camera_title')}
      </div>
      <div className="section-content" tabIndex={0}>
        {polyglot.t('my_sessions_v2_exam_rules_second_camera_content')}
      </div>
    </>
  );
};

/**
 * @typedef AllowedResourcesProps
 * @property {string[]} allowedResources
 */

/**
 * @param {AllowedResourcesProps} props
 * @returns {React.ReactNode}
 */
const AllowedResources = ({ allowedResources }) => {
  return (
    <>
      <div className="section-title mb-2" tabIndex={0}>
        {polyglot.t('my_sessions_v2_exam_rules_allowed_resources')}
      </div>
      <ul>
        {allowedResources?.map((resource) => (
          <li key={resource} tabIndex={0}>
            {resource}
          </li>
        ))}
      </ul>
    </>
  );
};

/**
 * @typedef AccommodationsProps
 * @property {Reservation} reservation
 */

/**
 * @param {AccommodationsProps} props
 * @returns {React.ReactNode}
 */
const Accommodations = ({ reservation }) => {
  const hasAccommodations = reservation.accommodations.length > 0;

  return (
    <>
      <div className="section-title mb-2" tabIndex={0}>
        {polyglot.t('my_sessions_v2_exam_rules_accommodations')}
      </div>
      {(reservation.displayDurationModifier || hasAccommodations) && (
        <ul>
          {reservation.displayDurationModifier && (
            <li tabIndex={0}>
              {polyglot.t('my_sessions_v2_exam_rules_total_allowed_duration', {
                duration: reservation.totalDurationFormatted,
              })}
            </li>
          )}
          {hasAccommodations &&
            reservation.accommodations.map((accommodation) => (
              <li key={accommodation} tabIndex={0}>
                {accommodation}
              </li>
            ))}
        </ul>
      )}

      <p tabIndex={0}>
        {!hasAccommodations &&
          (reservation.iteration.isLivePlus
            ? polyglot.t(
                'my_sessions_v2_exam_rules_proctor_verify_accommodation',
              )
            : polyglot.t('my_sessions_v2_exam_rules_no_accommodations'))}
      </p>
    </>
  );
};

/**
 * @typedef BreaksProps
 * @property {boolean} bathroomBreaks
 */

/**
 * @param {BreaksProps} props
 * @returns {React.ReactElement}
 */
const Breaks = ({ bathroomBreaks }) => {
  return (
    <>
      <div className="section-title mb-2" tabIndex={0}>
        {polyglot.t('my_sessions_v2_exam_rules_breaks')}
      </div>
      {bathroomBreaks ? (
        <p tabIndex={0}>
          {polyglot.t('my_sessions_v2_exam_rules_breaks_allowed')}
        </p>
      ) : (
        <p tabIndex={0}>
          {polyglot.t('my_sessions_v2_exam_rules_no_breaks_allowed')}
        </p>
      )}
    </>
  );
};

/**
 * @typedef OtherResourcesProps
 * @property {string} otherResources
 */

/**
 * @param {OtherResourcesProps} props
 * @returns {React.ReactElement}
 */
const OtherResources = ({ otherResources }) => {
  return (
    <>
      <div className="section-title mb-2" tabIndex={0}>
        {polyglot.t('my_sessions_v2_exam_rules_other_resources')}
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(otherResources),
        }}
        tabIndex={0}
      ></div>
    </>
  );
};

/**
 * @typedef ExamRulesModalProps
 * @property {Reservation} reservation
 * @property {boolean} show
 * @property {() => void} handleCloseModal
 */

/**
 * @param {ExamRulesModalProps} props
 * @returns {React.ReactNode}
 */
export const ExamRulesModal = ({ reservation, show, handleCloseModal }) => {
  const plainOtherResources = removeHtmlTags(
    reservation.iteration.otherResources,
  );

  return (
    <Modal
      show={show}
      onHide={handleCloseModal}
      size="md"
      className="p-0 my-sessions-v2-exam-rules"
      centered
    >
      <Modal.Header>
        <Modal.Title className="text-center w-100" tabIndex={0}>
          {polyglot.t('my_sessions_v2_exam_rules_exam_rule_check')}
        </Modal.Title>
        <Button
          variant="link"
          onClick={handleCloseModal}
          className="close"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body className="p-0">
        <Guidelines />
        <hr className="my-4" />
        <Rules reservation={reservation} />
        {reservation.iteration?.isSecondCameraEnabled && (
          <>
            <hr className="my-4" />
            <SecondCamera />
          </>
        )}
        {reservation.iteration.allowedResources.length > 0 && (
          <>
            <hr className="my-4" />
            <AllowedResources
              allowedResources={reservation.iteration.allowedResources}
            />
          </>
        )}
        <hr className="my-4" />
        <Accommodations reservation={reservation} />
        <hr className="my-4" />
        <Breaks bathroomBreaks={reservation.iteration?.bathroomBreaks} />
        {!!plainOtherResources && (
          <>
            <hr className="my-4" />
            <OtherResources
              otherResources={reservation.iteration.otherResources}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleCloseModal}
          data-type="ExamRulesClose"
          data-fulfillment-uuid={reservation.fulfillment?.uuid}
          data-behavior="event-button"
          data-data="yes"
          data-dismiss="modal"
        >
          {polyglot.t('my_sessions_v2_exam_rules_close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
