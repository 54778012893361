import moment from 'moment-timezone';

/**
 * Formats a date and time based on the given format.
 * @param {string} date - The date string in ISO 8601 format.
 * @param {string} format - The desired format (e.g., 'YYYY-MM-DD HH:mm').
 * @param {string} timezone - The timezone (e.g., 'America/New_York'). Defaults to browser's local timezone.
 * @returns {string} - The formatted date string.
 */
const formatDateTime = (
  datetime,
  format = 'YYYY-MM-DD HH:mmA z',
  //TODO: Implement below as a part of removing tech debt.
  // current version of moment-timezone (v0.4.0) doesn't support moment.tz.guess().
  // If the user has no configured time zone try to guess it, otherwise fallback to pacific time
  // timezone = moment.tz.guess() || 'US/Pacific',
  timezone = 'US/Pacific',
) => {
  if (!datetime) return '';

  // Ensure datetime is in ISO 8601 format
  const isoDateTime = moment(datetime).toISOString();

  // Format the ISO datetime using the specified timezone and format
  return moment(isoDateTime).tz(timezone).format(format);
};

/**
 * Determines if the time format is 24-hour.
 * @returns {boolean} - True if the time format is 24-hour; otherwise, false.
 */
const is24HourFormat = () => {
  const format = polyglot.t('my_sessions_v2_exam_history_item_formatted_time');
  return format === '24_hr_with_timezone';
};

export { formatDateTime, is24HourFormat };
