/**
 * @function displayMediaDevicesInfo
 *
 * @desc gets available media devices, creates dropdown and
 * displays it on the test-it-out / system metrics page.
 */
const constraints = {
  video: {
      width: { ideal: 4096 },
      height: { ideal: 2160 }
  },
  audio: true
};

const SESSION_STORAGE_PREFIX = 'system-metrics-';

function getDevices() {
  return navigator.mediaDevices.enumerateDevices();
}

function displayMediaDevicesInfo(changeEventCallback) {
  const microphoneSelect = document.querySelector(
    '[data-behavior="microphone-name"]'
  )
  const cameraSelect = document.querySelector(
    '[data-behavior="camera-name"]'
  )
  const audioOutputSelect = document.querySelector(
    '[data-behavior="audio-output-name"]'
  )

  return getUserMediaPermissions()
    .then(() => createDropdownFor(microphoneSelect, 'audioinput'))
    .then(() => createDropdownFor(audioOutputSelect, 'audiooutput'))
    .then(() => createDropdownFor(cameraSelect, 'videoinput'))
    .then(() => populateCameraResolution())
    .then(() => bindChangeEventToDropdowns(cameraSelect, microphoneSelect, audioOutputSelect, changeEventCallback));
}

function createDropdownFor(select, kind) {
  let count = 0;
  return getDevices()
    .then(devices => {
      devices.forEach(mediaDevice => {
        if (mediaDevice.kind === kind) {
          count++;
          const option = document.createElement('option');
          const label = mediaDevice.label || `${kind} ${count}`;
          option.value = label;
          const textNode = document.createTextNode(label);
          option.appendChild(textNode);
          select.appendChild(option);
        }
      })
      select.remove(0);
      selectDeviceByStoredValue(select, kind);
      if (count == 0) {
        const option = document.createElement('option');
        option.value = 'Default';
        const textNode = document.createTextNode('Default');
        option.appendChild(textNode);
        select.appendChild(option);
        select.disabled = true;
      }
    });
}

// select first device on drop down by default, pull device name from session storage if already set
function selectDeviceByStoredValue(select, kind) {
  select.selectedIndex = 0;
  const storedDeviceName = sessionStorage.getItem(`${SESSION_STORAGE_PREFIX}${kind}`);
  if (storedDeviceName !== null) {
    const matchedOption = [...select.options].find(option => option.value === storedDeviceName);
    if (matchedOption !== undefined) {
      select.value = matchedOption.value;
    }
  } else {
    sessionStorage.setItem(`${SESSION_STORAGE_PREFIX}${kind}`, select.value);
  }
}

function getUserMediaPermissions() {
  return navigator.mediaDevices.getUserMedia(constraints);
}

function bindChangeEventToDropdowns(cameraSelect, microphoneSelect, audioOutputSelect, changeEventCallback) {
  cameraSelect.addEventListener('change', () => {
    sessionStorage.setItem(`${SESSION_STORAGE_PREFIX}videoinput`, cameraSelect.value);
    populateCameraResolution().then(() => {
      changeEventCallback();
    });
  });
  microphoneSelect.addEventListener('change', () => {
    sessionStorage.setItem(`${SESSION_STORAGE_PREFIX}audioinput`, microphoneSelect.value);
    changeEventCallback();
  });
  audioOutputSelect.addEventListener('change', () => {
    sessionStorage.setItem(`${SESSION_STORAGE_PREFIX}audiooutput`, audioOutputSelect.value);
    changeEventCallback();
  });
}

function populateCameraResolution() {
  const cameraSelect = document.querySelector(
    '[data-behavior="camera-name"]'
  )
  const cameraWidth = document.querySelector('[data-behavior="camera-width"]');
  const cameraHeight = document.querySelector('[data-behavior="camera-height"]');

  const selectedValue = cameraSelect.options[cameraSelect.selectedIndex].value;
  return getCameraResolution(selectedValue).then(cameraSettings => {
    if (cameraSettings) {
      cameraWidth.value = cameraSettings.width;
      cameraHeight.value = cameraSettings.height;
    }
  });
}

function getCameraResolution(label) {
  return getDevices()
    .then(devices => {
      const id = devices.find((device => device.label == label)).deviceId;
      return navigator.mediaDevices.getUserMedia({
        video: { deviceId: id }
      })
    })
    .then(stream => {
      const tracks = stream.getVideoTracks();
      if (tracks.length == 0) {
        return null;
      }

      const settings = tracks[0].getSettings();
      tracks.forEach(track => {
        track.stop();
      });
      return {
        width: settings.width,
        height: settings.height
      }
    });
}

module.exports = displayMediaDevicesInfo;
