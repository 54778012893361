import { useCallback } from 'react';

import { mapKeys, snakeCase } from 'lodash';

import { apiPost } from '../api';

/**
 * @typedef PrechecksApiHookResult
 * @property {sendEventCallback} sendEvent
 * @property {broadcastMessageCallback} broadcastMessage
 * @property {sendSessionAwaitingVerificationEventCallback} sendSessionAwaitingVerificationEvent
 */

/**
 * @callback sendEventCallback
 * @async
 * @template RequestData, ResponseData
 * @param {string} eventType
 * @param {RequestData} [payload={}]
 * @param {string} [eventEndpointType='prechecks']
 * @param {(response: AxiosResponse<ResponseData>) => void} [responseFunction]
 * @param {(error: Error) => void} [errorFunction]
 * @returns {Promise<void>}
 */

/**
 * @callback broadcastMessageCallback
 * @async
 * @template Message
 * @param {Message} message
 * @returns {Promise<void>}
 */

/**
 * @callback sendSessionAwaitingVerificationEventCallback
 * @async
 * @param {number} createdById
 * @returns {Promise<void>}
 */

/**
 * @param {string} fulfillmentUuid
 * @returns {PrechecksApiHookResult}
 */
export default function usePrechecksApi(fulfillmentUuid) {
  const sendEvent = useCallback(
    async (
      eventType,
      payload = {},
      eventEndpointType = 'prechecks',
      responseFunction,
      errorFunction,
    ) => {
      const eventPayload = {
        uuid: fulfillmentUuid,
        event: {
          type: eventType,
          ...payload,
        },
      };

      try {
        const url = `${window.location.origin}/api/secure_browser/events/${eventEndpointType}`;
        const data = mapKeys(eventPayload, (value, key) => snakeCase(key));

        console.log(`Sending ${eventType} event:`, eventPayload);
        const response = await apiPost(url, data);
        console.log(response);
        responseFunction?.(response);
      } catch (error) {
        console.error(error);
        errorFunction?.(error);
      }
    },
    [fulfillmentUuid],
  );

  const broadcastMessage = useCallback(
    async (message) => {
      try {
        const url = `${window.location.origin}/api/secure_browser/fulfillments/${fulfillmentUuid}/broadcasting`;
        const data = { msg: JSON.stringify(message) };

        const response = await apiPost(url, data);
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    },
    [fulfillmentUuid],
  );

  const sendSessionAwaitingVerificationEvent = useCallback(
    async (createdById) => {
      try {
        const url = `${window.location.origin}/api/secure_browser/events/session_awaiting_verification`;
        const data = {
          uuid: fulfillmentUuid,
          event: { created_by_id: createdById },
        };

        const response = await apiPost(url, data);
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    },
    [fulfillmentUuid],
  );

  return { sendEvent, broadcastMessage, sendSessionAwaitingVerificationEvent };
}
