import { useEffect } from 'react';

/**
 * @interface ChatProvider
 * @property {() => void} openMessenger - Function to open the chat messenger
 * @property {() => void} closeMessenger - Function to close the chat messenger
 * @property {() => boolean} shouldShowChatWidget - Function to check if the chat widget should be shown
 * @property {() => void} unhideChatWidget - Function to unhide the chat widget
 * @property {() => void} hideChatWidget - Function to hide the chat widget
 */

/**
 * @class GenesysChatProvider
 * @implements {ChatProvider}
 *
 * Genesys is the current chat provider for the support chat
 */
class GenesysChatProvider {
  #MESSENGER_ID = 'genesys-messenger';
  #DATA_ATTRIBUTE_NAME = 'data-show-chat-widget';

  openMessenger() {
    this.unhideChatWidget();
    this.#chatWidgetCommand('Messenger.open');
  }

  closeMessenger() {
    this.unhideChatWidget();
    this.#chatWidgetCommand('Messenger.close');
  }

  shouldShowChatWidget() {
    const dataDiv = document.querySelector(`[${this.#DATA_ATTRIBUTE_NAME}]`);
    return dataDiv?.getAttribute(this.#DATA_ATTRIBUTE_NAME) === 'true';
  }

  hideChatWidget() {
    const messengerBtn = document.getElementById(this.#MESSENGER_ID);
    if (messengerBtn && !this.shouldShowChatWidget()) {
      messengerBtn.style.display = 'none';
    }
  }

  unhideChatWidget() {
    const dataDiv = document.querySelector(`[${this.#DATA_ATTRIBUTE_NAME}]`);
    const messengerBtn = document.getElementById(this.#MESSENGER_ID);
    if (messengerBtn) {
      dataDiv?.setAttribute(this.#DATA_ATTRIBUTE_NAME, 'true');
      messengerBtn.style.display = 'block';
    }
  }

  #chatWidgetCommand(...args) {
    if (!window.Genesys) {
      console.warn('Genesys is not initialized');
    }
    const result = window.Genesys?.('command', ...args);
    if (result !== true) {
      console.warn('Failed to execute Genesys command', args);
    }
    return result;
  }
}

/**
 * @typedef SupportChatHookResult
 * @property {() => void} openMessenger - Function to open the chat messenger
 * @property {() => void} closeMessenger - Function to close the chat messenger
 */

/**
 * A hook to manage the support chat functionality, which is currently implemented with Genesys chat.
 *
 * @returns { SupportChatHookResult }
 */
export default function useSupportChat() {
  const openMessenger = () => {
    new GenesysChatProvider().openMessenger();
  };

  const closeMessenger = () => {
    new GenesysChatProvider().closeMessenger();
  };

  return { openMessenger, closeMessenger };
}

/**
 * Used to hide the support chat provider on the page if needed
 */
export function useHideSupportChat() {
  useEffect(() => {
    const chatProvider = new GenesysChatProvider();

    if (chatProvider.shouldShowChatWidget()) {
      return;
    }

    // Set up a mutation observer to hide the chat widget if it's added to the page
    const observer = new MutationObserver((mutations) => {
      for (const _ of mutations) {
        chatProvider.hideChatWidget();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, []);
}
