import { useEffect } from 'react';

import PrechecksBodyText from '../CardSections/PrechecksBodyText';
import PrechecksFooterButton from '../CardSections/PrechecksFooterButton';
import { PrechecksVideoPlayer } from '../PrechecksVideoPlayer';
import usePrechecksApi from '../hooks/usePrechecksApi';
import ExamLobbyHeader from './ExamLobbyHeader';

const LmiConnectInstructions = ({ precheckData, isMac = true }) => {
  return (
    <PrechecksVideoPlayer
      idPrefix="lmi-connect-instruction"
      videoInfo={
        isMac
          ? precheckData.videos.lmiConnectInstructionMacMp4
          : precheckData.videos.lmiConnectInstructionWindowsMp4
      }
    />
  );
};

export default function ExamLobbyCard({
  id,
  icon = 'fa-check-circle',
  iconPrefix = 'fal',
  title = polyglot.t('prechecks_examlobby_liveplus_title_normal_wait'),
  bodyText = polyglot.t('prechecks_examlobby_liveplus_text'),
  extensionBodyText = polyglot.t('prechecks_examlobby_liveplus_extension_text'),
  extensionBodyText2 = polyglot.t(
    'prechecks_examlobby_liveplus_extension_text2',
  ),
  buttonText = polyglot.t('prechecks_examlobby_button'),
  precheckData,
  sendEvent,
  broadcastMessage,
  proctorConnected = false,
  handleBeginExam,
  lmiConnected,
}) {
  const { sendSessionAwaitingVerificationEvent } = usePrechecksApi(
    precheckData.fulfillment.uuid,
  );

  const isMac = navigator.userAgent.includes('Mac');
  const supportVideo = precheckData.videos.extensionSupportMp4;
  const isExtension = precheckData.extensionOverride;
  const isRecordPlus = !precheckData.livePlus;
  const isIntegratedLmiChat = precheckData.integratedLmiChat;
  const isEnableNativeChatForLiveProctoring =
    precheckData.enableNativeChatForLiveProctoring;
  const proctoruIcon = precheckData.images.proctoruIcon;
  const imageTag = `<img width="2%" src="${proctoruIcon?.src}" alt="${proctoruIcon?.altText}"/>`;
  const updatedBodyText = bodyText.replace('__image_tag__', imageTag);
  const showBeginExamButton =
    isRecordPlus ||
    (precheckData.guidedLaunch && !precheckData.removeBeginExam);
  const supportText = isExtension
    ? polyglot.t('prechecks_examlobby_auto_extension_text')
    : polyglot.t('prechecks_examlobby_auto_browser_text');

  const handleBeginExamClick = () => {
    sendEvent('Event::BeginExam');
    handleBeginExam();
  };

  const handleExtremeWait = () => {
    sendEvent('Event::ExamLobbyExtremeWait');
  };

  const handleSupportChatOpen = () => {
    sendEvent('Event::ExamLobbySupportChatOpened');
  };

  useEffect(() => {
    sendSessionAwaitingVerificationEvent(precheckData.user.id);

    if (isIntegratedLmiChat) {
      broadcastMessage({
        sender: 'archimedes',
        messageType: 'open_integrated_lmi_chat',
        data: {},
      });
    }
    if (isEnableNativeChatForLiveProctoring) {
      broadcastMessage({
        sender: 'archimedes',
        messageType: 'open_native_chat',
        data: {},
      });
    }
    if (precheckData.guidedLaunch) {
      if (precheckData.removeBeginExam) {
        sendEvent('Event::GuidedLaunchBeginExamLoaded');
      } else {
        sendEvent('Event::GuidedLaunchWaitingScreenLoaded');
      }
    }
  }, []);

  return (
    <div className="container-fluid text-center" id={id}>
      <div className="precheck-card-content">
        <ExamLobbyHeader
          title={title}
          icon={icon}
          iconPrefix={iconPrefix}
          precheckData={precheckData}
          showBeginExamButton={showBeginExamButton}
          lmiConnected={lmiConnected}
          proctorConnected={proctorConnected}
          onExtremeWait={handleExtremeWait}
          onSupportChatOpen={handleSupportChatOpen}
        />

        {isRecordPlus ? (
          <>
            <PrechecksBodyText bodyText={supportText} />
            {isExtension && (
              <div className="mb-4">
                <PrechecksVideoPlayer
                  idPrefix={'support-video'}
                  videoInfo={supportVideo}
                />
              </div>
            )}
          </>
        ) : (
          <div className="text-left">
            {isExtension ? (
              <>
                <PrechecksBodyText bodyText={extensionBodyText} />
                <PrechecksBodyText bodyText={extensionBodyText2} />
                <ul className="list-unstyled pl-2">
                  {!showBeginExamButton && (
                    <li className="mb-2">
                      <i
                        className={`far fa-check-circle precheck-dark fa-1x mr-2`}
                        aria-hidden="true"
                      />
                      {polyglot.t(
                        'prechecks_examlobby_liveplus_extension_item1',
                      )}
                    </li>
                  )}
                  <li className="mb-2">
                    <i
                      className={`far fa-check-circle precheck-dark fa-1x mr-2`}
                      aria-hidden="true"
                    />
                    {polyglot.t('prechecks_examlobby_liveplus_extension_item2')}
                  </li>
                  <li className="mb-2">
                    <i
                      className={`far fa-check-circle precheck-dark fa-1x mr-2`}
                      aria-hidden="true"
                    />
                    {polyglot.t('prechecks_examlobby_liveplus_extension_item3')}
                  </li>
                </ul>
              </>
            ) : (
              <>
                <PrechecksBodyText
                  bodyText={
                    isIntegratedLmiChat
                      ? polyglot.t(
                          'prechecks_examlobby_liveplus_browser_integrated_chat_text',
                        )
                      : updatedBodyText
                  }
                />
                <ul className="list-unstyled pl-2">
                  {!showBeginExamButton && (
                    <li className="mb-2">
                      <i
                        className={`far fa-check-circle precheck-dark fa-1x mr-2`}
                        aria-hidden="true"
                      />
                      {isIntegratedLmiChat
                        ? polyglot.t(
                            'prechecks_examlobby_liveplus_browser_integrated_chat_item1',
                          )
                        : polyglot.t(
                            'prechecks_examlobby_liveplus_browser_item1',
                          )}
                    </li>
                  )}
                  <li className="mb-2">
                    <i
                      className={`far fa-check-circle precheck-dark fa-1x mr-2`}
                      aria-hidden="true"
                    />
                    {polyglot.t('prechecks_examlobby_liveplus_browser_item2')}
                  </li>
                  <li className="mb-2">
                    <i
                      className={`far fa-check-circle precheck-dark fa-1x mr-2`}
                      aria-hidden="true"
                    />
                    {polyglot.t('prechecks_examlobby_liveplus_browser_item3')}
                  </li>
                </ul>
                {!isIntegratedLmiChat && (
                  <>
                    <div className="text-center">
                      <LmiConnectInstructions
                        precheckData={precheckData}
                        isMac={isMac}
                      />
                    </div>
                    <br /> {}
                    <PrechecksBodyText
                      bodyText={polyglot.t(
                        'prechecks_examlobby_liveplus_text2',
                      )}
                    />
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>

      {showBeginExamButton && (
        <PrechecksFooterButton
          buttonText={buttonText}
          handleClick={handleBeginExamClick}
        />
      )}
    </div>
  );
}
