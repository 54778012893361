import { OpenVidu } from 'openvidu-browser';

class CameraFeed {
  constructor() {
    this.OV = null;
    this.session = null;
    this.publisherCamera = null;
    this.cameraSelect = document.querySelector('[data-behavior="camera-name"]');
    this.webrtcStatsElement = document.querySelector('[data-behavior="webrtc-stats"]');
    this.handleCameraSelectChange = this.handleCameraSelectChange.bind(this);
  }

  init() {
    this.bindEventListeners();
    window.onbeforeunload = () => {
      this.session?.disconnect();
    };
    return this.joinSession();
  }

  async joinSession() {
    try {
      this.OV = new OpenVidu();
      this.session = this.OV.initSession();

      const token = await this.getToken();
      if (!token) throw new Error('Failed to retrieve token.');

      await this.session.connect(token);
      const devices = await this.OV.getDevices();
      const cameraLabel = sessionStorage.getItem('system-metrics-videoinput');

      if (cameraLabel) {
        this.initializePublisher(devices, cameraLabel);
      } else {
        const checkStorageInterval = setInterval(() => {
          const newCameraLabel = sessionStorage.getItem('system-metrics-videoinput');
          if (newCameraLabel) {
            clearInterval(checkStorageInterval);
            this.initializePublisher(devices, newCameraLabel);
          }
        }, 100);
      }
      document.querySelector('[data-behavior="camera-feed"]').style.display = 'block';
    } catch (error) {
      console.error('Error joining session:', error.message);
    }
  }

  leaveSession() {
    this.session?.disconnect();
  }

  async gatherStats() {
    try {
      const streamManager = this.session?.streamManagers?.[0];
      if (!streamManager) return true;

      const stats = await streamManager.stream.getRTCPeerConnection().getStats(null);
      const jsonPayload = {};
      stats.forEach((metric) => {
        jsonPayload[metric.id] = metric;
      });
      this.webrtcStatsElement.value = JSON.stringify(jsonPayload);
      return true;
    } catch (error) {
      console.error('Error gathering stats:', error.message);
      return true;
    }
  }

  async getToken() {
    try {
      const response = await $.ajax({ type: 'GET', url: '/users/system-metrics/openvidu/token' });
      return response.token;
    } catch (error) {
      console.error('Error retrieving token:', error);
      return null;
    }
  }

  bindEventListeners() {
    if (this.cameraSelect) {
      this.cameraSelect.addEventListener('change', this.handleCameraSelectChange);
    }
  }

  handleCameraSelectChange() {
    if (!this.cameraSelect) return;
    const select = this.cameraSelect;
    const selectedCamera = select.options[select.selectedIndex];
    this.toggleCamera(selectedCamera.value);
  }

  async toggleCamera(deviceLabel) {
    if (!deviceLabel) return;
    try {
      const devices = await this.OV.getDevices();
      this.initializePublisher(devices, deviceLabel);
    } catch (error) {
      console.error('Error toggling camera:', error.message);
    }
  }

  initializePublisher(devices, deviceLabel) {
    const videoDevices = devices.filter((device) => device.kind === 'videoinput');
    if (!videoDevices || videoDevices.length === 0) {
      console.warn('No video devices found.');
      return;
    }

    const targetDevice = videoDevices.find((device) => device.label === deviceLabel);
    if (!targetDevice) {
      console.warn('Specified video device not found:', deviceLabel);
      return;
    }

    try {
      // Unpublish the previous publisher if it exists
      if (this.publisherCamera) {
        this.session.unpublish(this.publisherCamera);
      }

      const options = {
        videoSource: targetDevice.deviceId,
        resolution: '320x240',
        frameRate: 30,
        insertMode: 'AFTER',
      };

      this.publisherCamera = this.OV.initPublisher('publisherCamera', options, () => {
        console.log('Publisher initialized with new camera:', deviceLabel);
      });

      this.session.publish(this.publisherCamera);
    } catch (error) {
      console.error('Error initializing publisher:', error.message);
    }
  }
}

export default CameraFeed;