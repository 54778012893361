import csrfToken from '../src/utils/csrf';

import axios from 'axios';

export function useLanguage() {
  const setPreferredLanguage = (languageName) => {
    const url = `${window.location.origin}/account/change_language`;
    const data = { preferred_language: languageName };
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken(),
      },
    };

    axios
      .patch(url, data, config)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return {
    setPreferredLanguage,
  };
}
