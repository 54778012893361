import React, { useState } from 'react';
import { MobileNavigation } from './MobileNavigation';
import LanguageDropdown from '../LanguageDropdown';

/**
 * NavbarV2 component is the primary navbar for version 2 design.
 * If the screen size is less than medium then show the secondary navbar for mobile in this component itself
 *
 * @param {NavbarV2Props} props
 * @returns {React.ReactNode}
 */
export function NavbarV2({ navbar, menuItems }) {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenuVisibility = () => {
    setShowMenu((previousValue) => !previousValue);
  };

  return (
    <>
      <a
        className="sr-only sr-only-focusable btn btn-secondary d-flex justify-content-center align-items-center fs-4 text-dark"
        href="#content"
        id="skip-to-content"
      >
        {polyglot.t('navbar_sr_skip')}
      </a>
      <nav
        className={`navbar navbar-dark navbar-expand-lg justify-content-center ${navbar.isProduction ? 'bg-dark' : 'bg-primary'}`}
        aria-label="Main navigation"
      >
        <div className="container">
          <a href={navbar.rootPath}>
            <img src={navbar.proctorULogo} alt="ProctorU Logo" height="36" />
          </a>
          <button
            className="d-block d-md-none desktop-navbar-v2"
            onClick={toggleMenuVisibility}
            aria-label="Toggle navigation menu"
          >
            <i className="fa-solid fa-bars"></i>
          </button>
          {!!navbar.timezone && (
            <div className="ml-5 text-white d-none d-md-block">
              {navbar.timezone}
            </div>
          )}
          <div
            className={`d-none d-md-flex flex-row nav navbar-nav ml-auto justify-content-end ${navbar.isAlias ? '' : 'w-100'}`}
          >
            {navbar.isAlias && (
              <div className="nav-item text-white">
                <a
                  className="nav-link"
                  rel="nofollow"
                  data-method="delete"
                  href={navbar.aliasPath}
                >
                  {polyglot.t('navbar_logout_alias')}
                </a>
              </div>
            )}
            <LanguageDropdown
              languages={navbar.availableLanguagesForUser}
              displayAsNavItem={true}
            />
            <div className="nav-item dropdown">
              <a
                href="application.html"
                className="nav-link dropdown-toggle text-white cursor-pointer text-decoration-none"
                id="nav-user-dropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                tabIndex="0"
              >
                {navbar.fullName}
              </a>
              <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="nav-user-dropdown"
                style={{ zIndex: 1051 }}
              >
                <h6 className="dropdown-header">
                  {polyglot.t('navbar_user_settings')}
                </h6>
                {navbar.hasSecondRole && (
                  <a
                    href={navbar.secondRoleLoginSessionPath}
                    className="dropdown-item"
                    rel="nofollow"
                    data-method="put"
                  >
                    {navbar.isCurrentRoleStudent
                      ? polyglot.t('navbar_login_as_instructor')
                      : polyglot.t('navbar_login_as_test_taker')}
                  </a>
                )}
                <a href={navbar.editAccountPath} className={'dropdown-item'}>
                  {polyglot.t('navbar_account_settings')}
                </a>
                {navbar.canEditPassword && (
                  <a href={navbar.editPasswordPath} className={'dropdown-item'}>
                    {polyglot.t('navbar_password_settings')}
                  </a>
                )}
                {navbar.isInstitutionUser && (
                  <>
                    <a
                      href={navbar.notificationAccountPath}
                      className="dropdown-item"
                    >
                      {polyglot.t('navbar_notification_settings')}
                    </a>
                    <h6 className="dropdown-header">
                      {polyglot.t('navbar_institution_settings')}
                    </h6>
                  </>
                )}
                {navbar.isStudent && (
                  <a
                    href={navbar.notificationStudentPrefPath}
                    className="dropdown-item"
                  >
                    {polyglot.t('navbar_notification_settings')}
                  </a>
                )}
                <div className="dropdown-divider"></div>
                <a href={navbar.logoutPath} className={'dropdown-item'}>
                  {polyglot.t('navbar_logout')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {showMenu && <MobileNavigation navbar={navbar} menuItems={menuItems} />}
    </>
  );
}
