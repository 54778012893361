import moment from 'moment';
import React from 'react';

/** @typedef {import('../MySessions').Reservation} Reservation */

/**
 * @typedef CountdownProps
 * @property {number} timeLeft - The time left, in milliseconds.
 * @property {boolean} [showSeconds] - Whether to show the seconds component.
 */

/**
 * `Countdown` component, used to display a given amount of time left until a
 * certain event (e.g., an exam reservation becoming accessible to the student).
 * @example
 *   <Countdown timeLeft={448200000} showSeconds={true} />  // 5 days 4 hours 30 minutes 0 seconds
 *   <Countdown timeLeft={448200000} />                     // 5 days 4 hours 30 minutes
 *   <Countdown timeLeft={448200999} showSeconds={true} />  // 5 days 4 hours 30 minutes 1 seconds
 *   <Countdown timeLeft={448200999} />                     // 5 days 4 hours 31 minutes
 *   <Countdown timeLeft={448215000} showSeconds={true} />  // 5 days 4 hours 30 minutes 15 seconds
 *   <Countdown timeLeft={448215000} />                     // 5 days 4 hours 31 minutes
 *   <Countdown timeLeft={448215999} showSeconds={true} />  // 5 days 4 hours 30 minutes 16 seconds
 *   <Countdown timeLeft={448215999} />                     // 5 days 4 hours 31 minutes
 * @param {CountdownProps} props
 * @returns {React.ReactNode}
 */
export default function Countdown({ timeLeft, showSeconds = false }) {
  const duration = moment.duration(timeLeft);

  /** @type {number} */
  let days, hours, minutes, seconds, milliseconds;

  days = Math.floor(duration.asDays());
  hours = duration.hours();
  minutes = duration.minutes();
  seconds = duration.seconds();
  milliseconds = duration.milliseconds();

  if (showSeconds) {
    if (milliseconds > 0) {
      seconds++;
    }
  } else {
    if (seconds > 0 || milliseconds > 0) {
      minutes++;
    }
  }

  return (
    <div data-testid="countdown" className="d-flex align-items-center">
      <TimeComponent value={days} units={polyglot.t('days')} />
      <TimeComponent value={hours} units={polyglot.t('hours')} />
      <TimeComponent value={minutes} units={polyglot.t('minutes')} />
      {showSeconds && (
        <TimeComponent value={seconds} units={polyglot.t('seconds')} />
      )}
    </div>
  );
}

/**
 * @typedef TimeComponentProps
 * @property {number} value - The time component value.
 * @property {string} units - The time component unit(s).
 */

/**
 * `TimeComponent` component, used to represent a time component in a duration.
 * @example
 *   <TimeComponent value={5} units={'days'} />      // 5 days
 *   <TimeComponent value={4} units={'hours'} />     // 4 hours
 *   <TimeComponent value={30} units={'minutes'} />  // 30 minutes
 *   <TimeComponent value={15} units={'seconds'} />  // 15 seconds
 * @param {TimeComponentProps} props
 * @returns {React.ReactNode}
 */
export function TimeComponent({ value, units }) {
  return (
    <>
      <strong className="fs-5 mr-1">{value}</strong>
      <span className="countdown-small-text">{units}</span>
    </>
  );
}
