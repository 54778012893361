import axios from 'axios';

import SlotsCard from '../../components/Reservations/TimeSlots/SlotsCard';
import csrfToken from '../utils/csrf';
import SlotsUrlBuilder from './SlotsUrlBuilder';
import { createRoot } from 'react-dom/client';

const SLOT_BUTTON_IDS = [
  '#js-reservation-reset-beginning',
  '#js-reservation-next-selection',
  '#js-find-slots'
]

class Slots {
  constructor() {
    this.reservationContent = document.querySelector('#reservation-content')
    this.loading = document.querySelector('.loading')
    this.slots = document.querySelector('.slots')
    this.slotsContainer = document.querySelector('.slots-container');
    this.slotsCard = document.querySelector('.time-slot-card');

    // NOTE: the server will include the data-slots-v2 attribute if the slots v2 feature is enabled
    this.slotsV2Enabled = this.slotsContainer.hasAttribute('data-slots-v2') &&
      this.slotsContainer.getAttribute('data-slots-v2') !== 'false';

    if(this.slotsV2Enabled) {
      // NOTE: this is a hack to avoid creating a new root every time this class is instantiated
      // this is because the slotsCard is a static element and we don't want to create a new root every time
      // the Slots class is instantiated
      this.slotsCardRoot = window.slotsCardRoot ?? createRoot(this.slotsCard);
      window.slotsCardRoot = this.slotsCardRoot;
    }
  }

  init() {
    if(!this.slotsCard) return;

    this.slots?.classList?.add('d-none');
    this.slotsContainer.classList.add('d-none');
    this.disableButtons();
    this.loading.classList.remove('d-none');

    this.requestTimeSlots();
  }

  async requestTimeSlots() {
    const delay = parseInt(this.reservationContent.getAttribute('data-slots-request-delay'));

    await new Promise(resolve => setTimeout(resolve, delay));

    try {
      const response = await axios.get(new SlotsUrlBuilder().init(), {
        headers: {
          'Accept': this.slotsV2Enabled ? 'application/json' : 'text/html',
          'Content-Type': this.slotsV2Enabled ? 'application/json' : 'text/html',
          'X-CSRF-Token': csrfToken(),
        }
      });

      if (this.slotsV2Enabled) {
        // NOTE: the unique key is used to force the component to re-render every time
        const component = React.createElement(SlotsCard, { ...response.data, key: Date.now() });
        this.slotsCardRoot.render(component);
      } else {
        // TODO: remove this once the slots v2 feature is fully rolled out
        while(this.slots.firstChild){ this.slots.removeChild(this.slots.firstChild); }
        this.slots.innerHTML = response.data;
      }

      this.slots?.classList?.remove('d-none');
      this.loading.classList.add('d-none');
      this.slotsContainer.classList.remove('d-none');
      this.enableButtons();
    } catch (error) {
      console.error('Error requesting time slots', error);
    }
  }

  disableButtons() {
    SLOT_BUTTON_IDS.forEach(button_id => {
      const button = document.querySelector(button_id)
      if(button) { button.classList.add('disabled') }
    })
  }

  enableButtons() {
    SLOT_BUTTON_IDS.forEach(button_id=> {
      const button = document.querySelector(button_id)
      if(button) { button.classList.remove('disabled') }
    })
  }
}

 export default Slots;
